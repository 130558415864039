import React from "react";
import * as auth from "../models/auth";

interface NavbarProps {
  user: any;
}

export const Navbar = (props: NavbarProps) => {
  const iamActive = (href: string) => {
    let url = window.location.href;
    return url.indexOf(href) !== -1;
  };

  const logout = () => {
    auth.logout();
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li
              className={`nav-item ${iamActive("/dashboard") ? "active" : ""}`}
            >
              <a className="nav-link" href="/dashboard">
                Dashboard
              </a>
            </li>
            <li
              className={`nav-item ${iamActive("/contacts") ? "active" : ""}`}
            >
              <a className="nav-link" href="/contacts">
                Contacts
              </a>
            </li>
            <li
              className={`nav-item ${iamActive("/accounts") ? "active" : ""}`}
            >
              <a className="nav-link" href="/accounts">
                Accounts
              </a>
            </li>
            <li className={`nav-item ${iamActive("/jobs") ? "active" : ""}`}>
              <a className="nav-link" href="/jobs">
                Jobs
              </a>
            </li>
            <li
              className={`nav-item ${
                iamActive("/settings/variables") ? "active" : ""
              }`}
            >
              <a className="nav-link" href="/settings/change-password">
                Settings
              </a>
            </li>
            {props.user && auth.userIsAdmin(props.user) && (
              <li
                className={`nav-item ${
                  iamActive("/admin/finance") ? "active" : ""
                }`}
              >
                <a className="nav-link" href="/admin/finance">
                  Admin
                </a>
              </li>
            )}
          </ul>
          <a
            className="my-2 my-sm-0 text-light nav-link"
            href="#"
            onClick={() => logout()}
          >
            <i className="fas fa-sign-out-alt mr-1"></i>
            Logout
          </a>
        </div>
      </nav>
    </>
  );
};
