import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import * as contactModel from "../../../models/contacts";
import * as accountModel from "../../../models/account";
import Select from "react-dropdown-select";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import { NavTabs } from "../navTabs";
import LoadingOverlay from "react-loading-overlay-ts";
import DatePicker from "react-datepicker";
import enAu from "date-fns/locale/en-AU";
import scroll from "react-scroll";

interface ContactProps {
  match: any;
}

interface Tags {
  label: any;
  value;
}

export const Contact = (props: ContactProps) => {
  const didMount = useRef(false);
  const [contact, setContact] = useState<contactModel.IContact>({ tags: "" });
  const [tags, setTags] = useState<Tags[]>();
  const [interestedIsOpen, setInterestedIsOpen] = useState<boolean>(false);
  const [interested, setInterested] = useState<string>("Yes");
  const [resultIsOpen, setResultIsOpen] = useState<boolean>(false);
  const [result, setResult] = useState<string>("Yes");
  const [errors, setErrors] = useState<contactModel.IContact>({});
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [accountDropDownIsOpen, setAccountDropDownIsOpen] =
    useState<boolean>(false);

  const [existingAccountIsOpen, setExistingAccountIsOpen] =
    useState<boolean>(false);
  const [accounts, setAccounts] = useState<accountModel.IAccount[]>();

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    saveContact();
  }, [contact.status, contact.accountId]);

  const saveContact = async () => {
    await contactModel.update(contact);
  };

  useEffect(() => {
    if (existingAccountIsOpen && !accounts) {
      initialiseAccounts();
    }
  }, [existingAccountIsOpen]);

  useEffect(() => {
    initialise();
  }, []);

  const initialiseAccounts = async () => {
    let response: any = await accountModel.list();
    let accounts = response.data.accounts;
    setAccounts(accounts);
  };

  const handleUpdate = async () => {
    try {
      await contactModel.contactSchema.validateSync(contact, {
        abortEarly: false,
      });
      await contactModel.update(contact);
      window.location.reload();
    } catch (yupErrors) {
      let errors: contactModel.IContact = {};
      yupErrors.inner.map((x: any) => {
        errors[x.path] = x.message;
      });
      setErrors(errors);
      scroll.scroller.scrollTo("text-danger", {
        duration: 800,
        delay: 0,
      });
    }
  };

  const getLifeCycleStage = (status: string) => {
    let lifeCycleStage: string;
    switch (status) {
      case "New":
      case "Contacted":
      case "Not Interested":
        lifeCycleStage = "Lead";
        break;
      case "Interested":
      case "Applied":
      case "Lost":
        lifeCycleStage = "Sales Qualified Lead";
        break;
      case "Won":
        lifeCycleStage = "Customer";
        break;
      default:
        lifeCycleStage = "Lead";
        break;
    }
    return lifeCycleStage;
  };

  const initialise = async () => {
    let response = await contactModel.get(props.match.params.id);
    let contact: contactModel.IContact = response.data.contact;
    let tags: Tags[] = [];
    contact.tags &&
      contact.tags.split(";").map((x) => {
        tags.push({ label: x, value: x });
      });
    setTags(tags);
    setContact(contact);
    setIsloading(false);
  };

  const customNoDataRenderer = () => {
    return <></>;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Contact | Acader Contract Management System</title>
      </Helmet>
      <Modal
        size="lg"
        isOpen={existingAccountIsOpen}
        toggle={() => setExistingAccountIsOpen((x) => !x)}
      >
        <ModalHeader>Select Account</ModalHeader>
        <ModalBody>
          <Label>Please select an account to associate</Label>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Account name</th>
                </tr>
              </thead>
              <tbody>
                {accounts &&
                  accounts.map((x, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <FormGroup check>
                            <Input
                              type="radio"
                              onChange={(e) => {
                                e.persist();
                                setContact((y) => ({
                                  ...y,
                                  accountId: x.id,
                                  contactType: "Consultant",
                                }));
                              }}
                            />
                          </FormGroup>
                        </td>
                        <td>{x.name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleUpdate}>
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Save status
          </Button>
          <Button onClick={() => setExistingAccountIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>
                  {contact && (
                    <>
                      {contact.firstName} {contact.lastName}
                    </>
                  )}
                </h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => (window.location.href = "/contacts")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                  Contacts
                </button>
                <br />
                <br />
                {contact && (
                  <>
                    <Modal
                      isOpen={interestedIsOpen}
                      toggle={() => setInterestedIsOpen((x) => !x)}
                    >
                      <ModalHeader>Interested?</ModalHeader>
                      <ModalBody>
                        <Label>Is the lead interested to proceed?</Label>
                        <select
                          className="form-control"
                          defaultValue={interested}
                          onChange={(e) => setInterested(e.currentTarget.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="success"
                          onClick={() => {
                            let status =
                              interested === "Yes"
                                ? "Interested"
                                : "Not Interested";
                            setContact((x) => ({
                              ...x,
                              status: status,
                              lifeCycleStage: getLifeCycleStage(status),
                            }));
                            window.location.reload();
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} className="mr-2" />
                          Save status
                        </Button>
                        <Button onClick={() => setInterestedIsOpen(false)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <Modal
                      isOpen={resultIsOpen}
                      toggle={() => setResultIsOpen((x) => !x)}
                    >
                      <ModalHeader>Result?</ModalHeader>
                      <ModalBody>
                        <Label>
                          Has this lead successfully converted to a Customer?
                        </Label>
                        <select
                          className="form-control"
                          defaultValue={interested}
                          onChange={(e) => setResult(e.currentTarget.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="success"
                          onClick={() => {
                            let status = result === "Yes" ? "Won" : "Lost";
                            setContact((x) => ({
                              ...x,
                              status: status,
                              lifeCycleStage: getLifeCycleStage(status),
                            }));
                            window.location.reload();
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} className="mr-2" />
                          Save status
                        </Button>
                        <Button onClick={() => setResultIsOpen(false)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <NavTabs
                      activeTab="general"
                      id={contact.id}
                      route="contact"
                    />
                    <h2 className="mt-3">General</h2>
                    <hr />
                    {contact.contactType === "Consultant" && (
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Status</Label>
                            <div className="arrow-steps clearfix mb-4">
                              <div
                                className={`step ${
                                  contact.status === "New" && "current"
                                }`}
                                onClick={() => {
                                  setContact((x) => ({
                                    ...x,
                                    status: "New",
                                    lifeCycleStage: getLifeCycleStage("New"),
                                  }));
                                }}
                              >
                                <span>New</span>
                              </div>
                              <div
                                className={`step ${
                                  contact.status === "Contacted" && "current"
                                }`}
                                onClick={() => {
                                  setContact((x) => ({
                                    ...x,
                                    status: "Contacted",
                                    lifeCycleStage:
                                      getLifeCycleStage("Contacted"),
                                  }));
                                }}
                              >
                                <span>Contacted</span>
                              </div>
                              <div
                                className={`step ${
                                  (contact.status === "Interested" &&
                                    "current") ||
                                  (contact.status === "Not Interested" &&
                                    "blocked")
                                }`}
                                onClick={() => setInterestedIsOpen(true)}
                              >
                                <span>
                                  {contact.status === "Interested" ||
                                  contact.status === "Not Interested"
                                    ? contact.status
                                    : "Interested?"}
                                </span>
                              </div>
                              <div
                                className={`step ${
                                  contact.status === "Applied" && "current"
                                }`}
                                onClick={() => {
                                  setContact((x) => ({
                                    ...x,
                                    status: "Applied",
                                    lifeCycleStage:
                                      getLifeCycleStage("Applied"),
                                  }));
                                }}
                              >
                                <span>Applied</span>
                              </div>
                              <div
                                className={`step ${
                                  (contact.status === "Won" && "won") ||
                                  (contact.status === "Lost" && "blocked")
                                }`}
                                onClick={() => setResultIsOpen(true)}
                              >
                                <span>
                                  {contact.status === "Won" ||
                                  contact.status === "Lost"
                                    ? contact.status
                                    : "Result?"}
                                </span>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      {contact.contactType === "Consultant" && (
                        <Col md={6}>
                          <FormGroup>
                            <Label>Lead status</Label>
                            <Input readOnly value={contact.lifeCycleStage} />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtAccountPiker">Account</Label>
                          <InputGroup>
                            <Input
                              id="txtAccountPiker"
                              readOnly
                              value={
                                (contact &&
                                  contact.account &&
                                  contact.account.name) ||
                                ""
                              }
                            />
                            <ButtonDropdown
                              isOpen={accountDropDownIsOpen}
                              toggle={() => setAccountDropDownIsOpen((x) => !x)}
                            >
                              <DropdownToggle caret>Actions</DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => setExistingAccountIsOpen(true)}
                                >
                                  Add existing
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    (window.location.href = "/accounts")
                                  }
                                >
                                  Add new
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setContact((x) => ({
                                      ...x,
                                      accountId: null,
                                      account: null,
                                    }));
                                  }}
                                >
                                  Clear
                                </DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtJobTitle" className="required">
                            Job title
                          </Label>
                          <Input
                            autoComplete="off"
                            id="txtJobTitle"
                            value={contact.jobTitle}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                jobTitle: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.jobTitle && (
                            <Label className="text-danger">
                              {errors.jobTitle}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtFirstName" className="required">
                            First name
                          </Label>
                          <Input
                            id="txtFirstName"
                            type="text"
                            autoComplete="off"
                            value={contact.firstName}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                firstName: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.firstName && (
                            <Label className="text-danger">
                              {errors.firstName}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtLastName" className="required">
                            Last name
                          </Label>
                          <Input
                            id="txtLastName"
                            autoComplete="off"
                            value={contact.lastName}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                lastName: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.lastName && (
                            <Label className="text-danger">
                              {errors.lastName}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtPreferredName" className="required">
                            Preferred name
                          </Label>
                          <Input
                            id="txtPreferredName"
                            autoComplete="off"
                            value={contact.preferredName}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                preferredName: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.preferredName && (
                            <Label className="text-danger">
                              {errors.preferredName}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="ddlContactType">Contact type</Label>
                          <select
                            className="form-control"
                            id="ddlContactType"
                            value={contact.contactType}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                contactType: e.target.value,
                              }));
                            }}
                          >
                            <option value="">Please select...</option>
                            <option value="Consultant">Consultant</option>
                            <option value="Client">Client</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtEmail" className="required">
                            Email
                          </Label>
                          <Input
                            id="txtEmail"
                            autoComplete="off"
                            value={contact.email}
                            type="email"
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                email: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.email && (
                            <Label className="text-danger">
                              {errors.email}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtMobile" className="required">
                            Mobile
                          </Label>
                          <Input
                            id="txtMobile"
                            autoComplete="off"
                            value={contact.mobile}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                mobile: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.mobile && (
                            <Label className="text-danger">
                              {errors.mobile}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtEmail2">Email 2</Label>
                          <Input
                            id="txtEmail2"
                            autoComplete="off"
                            value={contact.email2}
                            type="email"
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                email2: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtOtherPhone">Other phone</Label>
                          <Input
                            id="txtOtherPhone"
                            autoComplete="off"
                            value={contact.otherPhone}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                otherPhone: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="txtInvoiceNumberTemplate">
                            Invoice number template
                          </Label>
                          <Input
                            id="txtInvoiceNumberTemplate"
                            autoComplete="off"
                            type="url"
                            value={contact.invoiceNumberTemplate}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                invoiceNumberTemplate: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    {contact.contactType === "Consultant" && (
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="txtRate">Rate</Label>
                            <Input
                              id="txtRate"
                              autoComplete="off"
                              type="number"
                              value={contact.rate}
                              onChange={(e) => {
                                e.persist();
                                setContact((x) => ({
                                  ...x,
                                  rate: parseFloat(e.target.value),
                                }));
                              }}
                            ></Input>
                          </FormGroup>

                          <FormGroup>
                            <Label for="txtServiceFee">Service fee</Label>
                            <Input
                              id="txtServiceFee"
                              autoComplete="off"
                              type="number"
                              value={contact.serviceFee}
                              onChange={(e) => {
                                e.persist();
                                setContact((x) => ({
                                  ...x,
                                  serviceFee: parseFloat(e.target.value),
                                }));
                              }}
                            ></Input>
                          </FormGroup>

                          <FormGroup>
                            <Label for="txtPayrollFee">Payroll fee</Label>
                            <Input
                              id="txtPayrollFee"
                              autoComplete="off"
                              type="number"
                              value={contact.payrollFee}
                              onChange={(e) => {
                                e.persist();
                                setContact((x) => ({
                                  ...x,
                                  payrollFee: parseFloat(e.target.value),
                                }));
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="txtInternalContract">
                              Internal reference number
                            </Label>
                            <Input
                              id="txtInternalContract"
                              autoComplete="off"
                              type="url"
                              value={contact.internalReferenceNumber}
                              onChange={(e) => {
                                e.persist();
                                setContact((x) => ({
                                  ...x,
                                  internalReferenceNumber: e.target.value,
                                }));
                              }}
                            ></Input>
                          </FormGroup>

                          <FormGroup>
                            <Label for="txtContractStartDate">
                              Contract start date
                            </Label>
                            <DatePicker
                              id="txtContractStartDate"
                              autoComplete="off"
                              className="form-control"
                              dateFormat="dd-MM-yyyy"
                              locale={enAu}
                              selected={
                                contact.contractStartDate &&
                                new Date(contact.contractStartDate)
                              }
                              onChange={(date: Date) =>
                                setContact((x) => ({
                                  ...x,
                                  contractStartDate: date,
                                }))
                              }
                            />
                            {errors.contractStartDate && (
                              <Label className="text-danger">
                                {errors.contractStartDate}
                              </Label>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="txtLinkedIn">LinkedIn Url</Label>
                            <Input
                              id="txtLinkedIn"
                              autoComplete="off"
                              type="url"
                              value={contact.linkedinProfile}
                              onChange={(e) => {
                                e.persist();
                                setContact((x) => ({
                                  ...x,
                                  linkedinProfile: e.target.value,
                                }));
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="txtClientContract">
                              Client Contract Number
                            </Label>
                            <Input
                              id="txtClientContract"
                              autoComplete="off"
                              type="url"
                              value={contact.clientContract}
                              onChange={(e) => {
                                e.persist();
                                setContact((x) => ({
                                  ...x,
                                  clientContract: e.target.value,
                                }));
                              }}
                            ></Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="txtTags">Tags</Label>
                            <Select<Tags>
                              className="form-control"
                              multi
                              create
                              options={[]}
                              values={tags}
                              onChange={(tags) => {
                                setContact((x) => ({
                                  ...x,
                                  tags: tags.map((x: any) => x.value).join(";"),
                                }));
                              }}
                              placeholder="type a tag and press enter"
                              noDataRenderer={customNoDataRenderer}
                              pattern="^[0-9]*$"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="ddlClearance">Security clearance</Label>
                            <select
                              className="form-control"
                              id="ddlClearance"
                              value={contact.clearance}
                              onChange={(e) => {
                                e.persist();
                                setContact((x) => ({
                                  ...x,
                                  clearance: e.target.value,
                                }));
                              }}
                            >
                              <option value="">Please select...</option>
                              <option value="Australian Citizen">
                                Australian Citizen
                              </option>
                              <option value="Baseline">Baseline</option>
                              <option value="Baseline In Progress">
                                Baseline In Progress
                              </option>
                              <option value="Negative vetting 1">
                                Negative vetting 1
                              </option>
                              <option value="Negative vetting 2">
                                Negative vetting 2
                              </option>
                              <option value="Top secret negative vetting">
                                Top secret negative vetting
                              </option>
                              <option value="Top secret positive vetting">
                                Top secret positive vetting
                              </option>
                            </select>
                          </FormGroup>

                          <FormGroup>
                            <Label for="txtContractEndDate">
                              Contract end date
                            </Label>
                            <DatePicker
                              id="txtContractEndDate"
                              className="form-control"
                              dateFormat="dd-MM-yyyy"
                              locale={enAu}
                              selected={
                                contact.contractEndDate &&
                                new Date(contact.contractEndDate)
                              }
                              onChange={(date: Date) =>
                                setContact((x) => ({
                                  ...x,
                                  contractEndDate: date,
                                }))
                              }
                            />
                            {errors.contractEndDate && (
                              <Label className="text-danger">
                                {errors.contractEndDate}
                              </Label>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <h2>Address</h2>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtAddressLine1">Address line 1</Label>
                          <Input
                            id="txtAddressLine1"
                            autoComplete="off"
                            value={contact.addressLine1}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                addressLine1: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtAddressLine2">Address line 2</Label>
                          <Input
                            id="txtAddressLine2"
                            autoComplete="off"
                            value={contact.addressLine2}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                addressLine2: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtAddressLine3">Address line 3</Label>
                          <Input
                            id="txtAddressLine3"
                            autoComplete="off"
                            value={contact.addressLine3}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                addressLine3: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtCity">City</Label>
                          <Input
                            id="txtCity"
                            autoComplete="off"
                            value={contact.city}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                city: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtSuburb">Suburb</Label>
                          <Input
                            id="txtSuburb"
                            autoComplete="off"
                            value={contact.suburb}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                suburb: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtPostcode">Postcode</Label>
                          <Input
                            id="txtPostcode"
                            autoComplete="off"
                            value={contact.postCode}
                            onChange={(e) => {
                              e.persist();
                              setContact((x) => ({
                                ...x,
                                postCode: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Button className="btn-dark" onClick={handleUpdate}>
                          <FontAwesomeIcon icon={faSave} className="mr-2" />
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
