import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faPaperPlane,
  faPencil,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as contactModel from "../../../models/contacts";
import * as contactJobModel from "../../../models/contactJob";
import * as jobModel from "../../../models/job";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { NavTabs } from "../navTabs";
import LoadingOverlay from "react-loading-overlay-ts";

interface ContactDocumentProps {
  match: any;
}

export const ContactOpportunities = (props: ContactDocumentProps) => {
  const [contact, setContact] = useState<contactModel.IContact>({});
  const [contactJobs, setContactJobs] =
    useState<contactJobModel.IContactJob[]>();
  const [jobs, setJobs] = useState<jobModel.IJob[]>();

  const [contactJob, setContactJob] = useState<contactJobModel.IContactJob>({});
  const [errors, setErrors] = useState<contactJobModel.IContactJob>({});
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [newOpportunityIsOpen, setNewOpportunityIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response = await contactModel.get(props.match.params.id);
    let contact: contactModel.IContact = response.data.contact;

    let contactJobResponse = await contactJobModel.list(contact.id);

    let contactJobs: contactJobModel.IContactJob[] =
      contactJobResponse.data.contactJobs;

    let jobResponse = await jobModel.list();
    let jobs: jobModel.IJob[] = jobResponse.data.jobs;

    setJobs(jobs);

    setContactJobs(contactJobs);
    setContactJob((x) => ({ ...x, contactId: contact.id }));

    setContact(contact);
    setIsloading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Contact Opportunities | Acader Contract Management System</title>
      </Helmet>

      <Modal
        size="lg"
        isOpen={newOpportunityIsOpen}
        toggle={() => setNewOpportunityIsOpen((x) => !x)}
      >
        <ModalHeader>New Contact</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="txtFirstName">Name</Label>
                <Input
                  id="txtFirstName"
                  autoComplete="off"
                  readOnly
                  value={contact.firstName + " " + contact.lastName}
                />
              </FormGroup>
              <FormGroup>
                <Label for="txtEmail">Email</Label>
                <Input
                  id="txtEmail"
                  autoComplete="off"
                  value={contact.email}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="txtRate" className="required">
                  Rate
                </Label>
                <Input
                  id="txtRate"
                  autoComplete="off"
                  value={contactJob.rate}
                  onChange={(e) => {
                    e.persist();
                    setContactJob((x) => ({
                      ...x,
                      rate: e.target.value,
                    }));
                  }}
                />
                {errors.rate && (
                  <Label className="text-danger">{errors.rate}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtJob" className="required">
                  Opportunity
                </Label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    e.persist();
                    setContactJob((x) => ({
                      ...x,
                      jobId: e.target.value,
                    }));
                  }}
                >
                  <option value="">Please select...</option>
                  {jobs &&
                    jobs.map((x, i) => {
                      return (
                        <option key={i} value={x.id}>
                          {x.opportunityId + " - " + x.title}
                        </option>
                      );
                    })}
                </select>
                {errors.jobId && (
                  <Label className="text-danger">{errors.jobId}</Label>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              setErrors({});
              try {
                await contactJobModel.contactJobSchema.validateSync(
                  contactJob,
                  {
                    abortEarly: false,
                  }
                );
                await contactJobModel.create(contactJob);
                window.location.reload();
              } catch (yupErrors) {
                let errors: contactJobModel.IContactJob = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
            Send opportunity
          </Button>
          <Button onClick={() => setNewOpportunityIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>
                  {contact && (
                    <>
                      {contact.firstName} {contact.lastName}
                    </>
                  )}
                </h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => (window.location.href = "/contacts")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                  Contacts
                </button>
                <br />
                <br />
                {contact && (
                  <>
                    <NavTabs
                      activeTab="opportunities"
                      id={contact.id}
                      route="contact"
                    />

                    <Row>
                      <Col>
                        <button
                          className="btn btn-dark"
                          onClick={() => setNewOpportunityIsOpen(true)}
                        >
                          <FontAwesomeIcon icon={faRocket} className="mr-2" />
                          Send an oppotunity
                        </button>
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <h2 className="mt-3 mb-4">Opportunities sent</h2>
                    <section className="container">
                      <aside>
                        <div className="table-responsive">
                          <Table striped>
                            <thead>
                              <tr>
                                <th>Job title</th>
                                <th>Job Id</th>
                                <th>Rate</th>
                                <th>Department</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {contactJobs &&
                                contactJobs.map((x, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{x.job.title}</td>
                                      <td>{x.job.opportunityId}</td>
                                      <td>{x.rate}</td>
                                      <td>{x.job.departmentName}</td>
                                      <td>
                                        <button
                                          className="btn btn-dark"
                                          onClick={() =>
                                            (window.location.href = `/contactJob/${x.id}`)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faPencil}
                                            className="mr-2"
                                          />
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      </aside>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
