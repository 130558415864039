import axios from "axios";
import { IUser } from "./user";
import * as yup from "yup";

export interface IJob {
  id?: string;
  title?: string;
  opportunityId?: string;
  departmentName?: string;
  overview?: string;
  essentialCriteria?: string;
  desirableCriteria?: string;
  locationOfWork?: string;
  contractDuration?: string;
  extentionOptions?: string;
  clearance?: string;
  deadline?: Date;
  expectedStartDate?: Date;
  scData?: any;
  spent?: number;
  modifiedBy?: string;
  createdBy?: string;
  updatedAt?: string;
  ModifiedByUser?: IUser;
}

export let build = async (id: string, opportunityId: string, title: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/job/build",
    {
      responseType: "blob",
      params: { id: id },
      withCredentials: true,
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Selection Criteria-${title}-${opportunityId}.pdf`
  ); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export let download = async (
  id: string,
  opportunityId: string,
  title: string
) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/job/download",
    {
      responseType: "blob",
      params: { id: id },
      withCredentials: true,
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Selection Criteria-${title}-${opportunityId}.pdf`
  ); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export let uploadDocument = async (files: File[], jobId) => {
  let postContent = new FormData();
  postContent.append("id", jobId);
  postContent.append("modifiedBy", window.$user.id);
  postContent.append("createdBy", window.$user.id);
  files.map((x) => {
    postContent.append("file", x);
  });

  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/job/upload",
    postContent,
    {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response;
};

export let create = async (job: IJob) => {
  job.createdBy = window.$user.id;
  job.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/job/create",
    { data: job },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let list = async () => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/job/list",
    {
      withCredentials: true,
    }
  );
  return response;
};

export let get = async (id: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/job/get",
    {
      params: { id: id },
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (job: IJob) => {
  job.scData = null;
  job.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/job/update",
    { data: job },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let deleteOne = async (id: string) => {
  let response: any = await axios.delete(
    process.env.REACT_APP_API_URL + "/job/destroy",
    {
      params: { id: id },
      withCredentials: true,
    }
  );
  return response;
};

export const jobSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .defined("Title is required"),
  opportunityId: yup
    .string()
    .required("Opportunity Id is required")
    .defined("Opportunity Id is required"),
  departmentName: yup
    .string()
    .required("Department Name is required")
    .defined("Department Name is required"),
  overview: yup
    .string()
    .required("Overview is required")
    .defined("Overview is required"),
  essentialCriteria: yup
    .string()
    .required("Essential Criteria is required")
    .defined("Essential Criteria is required"),
  desirableCriteria: yup
    .string()
    .required("Desirable Criteria is required")
    .defined("Desirable Criteria is required"),
  locationOfWork: yup
    .string()
    .required("Location of work is required")
    .defined("Location of work is required"),
  contractDuration: yup
    .string()
    .required("Contract duration is required")
    .defined("Contract duration is required"),
  extentionOptions: yup
    .string()
    .required("Extension options is required")
    .defined("Extension options is required"),
  clearance: yup
    .string()
    .required("Clearance is required")
    .defined("Clearance is required"),
  deadline: yup
    .date()
    .required("Duration is required")
    .defined("Duration is required"),
  expectedStartDate: yup
    .date()
    .required("Expected start date is required")
    .defined("Expected start date is required"),
});
