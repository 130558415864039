/* eslint-disable react/jsx-no-target-blank */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Dashboard } from "./dashboard";
import { Navbar } from "../../components/navbar";
import { Contacts } from "./contacts/contacts";
import * as auth from "../../models/auth";
import { Accounts } from "./accounts/accounts";
import { Contact } from "./contacts/contact";
import { ContactDocuments } from "./contacts/documents";
import { ContactActivities } from "./contacts/activities";
import { ContactNotes } from "./contacts/notes";
import { Variables } from "./settings/variables";
import { ChangePassword } from "./settings/change-password";
import { Account } from "./accounts/account";
import { AccountDocuments } from "./accounts/documents";
import { AccountActivities } from "./accounts/activities";
import { AccountNotes } from "./accounts/notes";
import { RateCalculator } from "./settings/rate-calculator";
import { ContactRateBreakdown } from "./contacts/rate-breakdown";
import { FinancePage } from "./admin/finance";
import { AccountContacts } from "./accounts/contacts";
import { UsersPage } from "./admin/users";
import { AccountConsultants } from "./accounts/consultants";
import { Jobs } from "./jobs/jobs";
import { Job } from "./jobs/job";
import { ContactOpportunities } from "./contacts/opportunities";
import { ContactJob } from "./contacts/contactJob";

export const SecureLayout = () => {
  const [user, setUser] = useState();
  useEffect(() => {
    authenticate();
  }, []);

  const authenticate = async () => {
    let user = await auth.authenticate();
    setUser(user);
    if (!user) {
      window.location.href = "/";
    }
  };
  return (
    <>
      <div className="theme-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-12">{user && <Navbar user={user} />}</div>
          </div>
        </div>
      </div>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/contacts" component={Contacts} />
        <Route exact path="/accounts" component={Accounts} />
        <Route exact path="/jobs" component={Jobs} />
        <Route
          exact
          path="/contact/:id"
          render={({ match }) => <Contact match={match} />}
        />

        <Route
          exact
          path="/job/:id"
          render={({ match }) => <Job match={match} />}
        />

        <Route
          exact
          path="/account/:id"
          render={({ match }) => <Account match={match} />}
        />

        <Route
          exact
          path="/account/documents/:id"
          render={({ match }) => <AccountDocuments match={match} />}
        />
        <Route
          exact
          path="/contact/documents/:id"
          render={({ match }) => <ContactDocuments match={match} />}
        />
        <Route
          exact
          path="/contact/activities/:id"
          render={({ match }) => <ContactActivities match={match} />}
        />
        <Route
          exact
          path="/account/activities/:id"
          render={({ match }) => <AccountActivities match={match} />}
        />
        <Route
          exact
          path="/account/contacts/:id"
          render={({ match }) => <AccountContacts match={match} />}
        />
        <Route
          exact
          path="/account/consultants/:id"
          render={({ match }) => <AccountConsultants match={match} />}
        />
        <Route
          exact
          path="/contact/notes/:id"
          render={({ match }) => <ContactNotes match={match} />}
        />
        <Route
          exact
          path="/account/notes/:id"
          render={({ match }) => <AccountNotes match={match} />}
        />
        <Route
          exact
          path="/contact/rate-breakdown/:id"
          render={({ match }) => <ContactRateBreakdown match={match} />}
        />
        <Route
          exact
          path="/contact/opportunities/:id"
          render={({ match }) => <ContactOpportunities match={match} />}
        />
        <Route
          exact
          path="/contactJob/:id"
          render={({ match }) => <ContactJob match={match} />}
        />
        <Route
          exact
          path="/settings/change-password"
          component={ChangePassword}
        />
        <Route exact path="/settings/variables" component={Variables} />
        <Route
          exact
          path="/settings/rate-calculator"
          component={RateCalculator}
        />
        {user && (
          <Route
            exact
            path="/admin/finance"
            render={() => <FinancePage user={user} />}
          />
        )}
        <Route exact path="/admin/users" component={UsersPage} />
        <Route exact path="/accounts" component={Accounts} />
      </Switch>
    </>
  );
};
