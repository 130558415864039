import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Input, Row, Table } from "reactstrap";
import { NavTabs } from "./navTabs";
import { list } from "../../../models/variable";

interface AccountActivitiesProps {
  match: any;
}

export const RateCalculator = (props: AccountActivitiesProps) => {
  const [superAnnuation, setSuperAnnuation] = useState<number>();
  const [rate, setRate] = useState<number>(160);
  const [hours, setHours] = useState<number>(160);
  const [payrollRate, setPayrollrate] = useState<number>(1.5);
  const [gross, setGross] = useState<number>(0);
  const [payroll, setPayroll] = useState<number>(0);
  const [superAmount, setSuperAmount] = useState<number>(0);

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response: any = await list();
    let superAnnuation = response.data.variables.find(
      (x) => x.name.toLocaleLowerCase() === "superannuation"
    ).value;
    setSuperAnnuation(parseFloat(superAnnuation));
  };

  useEffect(() => {
    setGross(rate * hours);
  }, [rate, hours]);

  useEffect(() => {
    setPayroll((gross * payrollRate) / 100);
    let superFactor = superAnnuation / 100 + 1;
    let superAmount = (gross - payroll) / superFactor;
    setSuperAmount(superAmount);
  }, [gross, superAnnuation]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>
          Monthly Salary Calculator | Acader Contract Management System
        </title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Settings</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-white">
        <Container>
          <Row>
            <Col>
              <NavTabs route="rate-calculator" />
              <h2 className="mt-5">Monthly Salary Breakdown</h2>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="table-responsive">
                <Table>
                  <tr>
                    <th>Superannuation</th>
                    <td>{superAnnuation}</td>
                  </tr>
                  <tr>
                    <th>Hours</th>
                    <td>
                      <Input
                        type="number"
                        value={hours}
                        onChange={(e) => {
                          e.persist();
                          let hours = parseFloat(e.target.value);
                          setHours(hours);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Rate</th>
                    <td>
                      <Input
                        type="number"
                        value={rate}
                        onChange={(e) => {
                          e.persist();
                          let rate = parseFloat(e.target.value);
                          setRate(rate);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Payroll rate (%)</th>
                    <td>
                      <Input
                        type="number"
                        value={payrollRate}
                        onChange={(e) => {
                          e.persist();
                          setPayrollrate(parseFloat(e.target.value));
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Gross</th>
                    <td>
                      {gross > 0 && (
                        <>
                          <>$</>
                          {gross.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Payroll fees</th>
                    <td>
                      {payroll > 0 && (
                        <>
                          <>$</>
                          {payroll.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Banked amount</th>
                    <td>
                      {superAnnuation > 0 && (
                        <>
                          <>$</>
                          {superAmount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                    </td>
                  </tr>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
