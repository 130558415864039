import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import * as accountModel from "../../../models/account";
import * as documentModel from "../../../models/document";
import { Table } from "reactstrap";

import { useDropzone } from "react-dropzone";
import { DocumentActions } from "../documentActions";
import { NavTabs } from "../navTabs";
import LoadingOverlay from "react-loading-overlay-ts";

interface AccountDocumentProps {
  match: any;
}

export const AccountDocuments = (props: AccountDocumentProps) => {
  const [account, setAccount] = useState<accountModel.IAccount>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<documentModel.IDocument[]>();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => uploadFiles(files),
  });
  useEffect(() => {
    initialise();
  }, []);

  const uploadFiles = async (files: File[]) => {
    await documentModel.createAccountDocument(files, account.id);
    window.location.reload();
  };

  const initialise = async () => {
    let response = await accountModel.get(props.match.params.id);
    let account: accountModel.IAccount = response.data.account;

    let documentResponse = await documentModel.listForAccount(account.id);
    let documents: documentModel.IDocument[] =
      documentResponse.data.attachments;

    setAccount(account);
    setDocuments(documents);
    setIsloading(false);
  };

  const files = acceptedFiles.map((file, i) => <li key={i}>{file.name}</li>);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Account documents | Acader Contract Management System</title>
      </Helmet>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>{account && <>{account.name}</>}</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => (window.location.href = "/accounts")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                  Accounts
                </button>
                <br />
                <br />
                {account && (
                  <>
                    <NavTabs
                      activeTab="documents"
                      id={account.id}
                      route="account"
                    />
                    <h2 className="mt-3">Documents</h2>
                    <hr />

                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' Drop files here, or click to select files
                        </p>
                      </div>
                      <aside>
                        <div className="table-responsive">
                          <Table striped>
                            <thead>
                              <tr>
                                <th>File name</th>
                                <th>Type</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {documents &&
                                documents.map((x, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{x.fileName}</td>
                                      <td>{x.type}</td>
                                      <td>
                                        <DocumentActions document={x} />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      </aside>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
