import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as contactModel from "../../../models/contacts";
import { Table } from "reactstrap";
import { NavTabs } from "./navTabs";
import LoadingOverlay from "react-loading-overlay-ts";
import * as auth from "../../../models/auth";
import { IUser } from "../../../models/user";

interface FinancePageProps {
  user: IUser;
}

export const FinancePage = (props: FinancePageProps) => {
  var totalMonthlyApprox: number = 0;
  var totalAnnualApprox: number = 0;
  const [contacts, setContacts] = useState<contactModel.IContact[]>();
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    if (!auth.userIsAdmin(props.user)) {
      window.location.href = "/unauthorised";
    } else {
      initialise();
    }
  }, [props.user]);

  const initialise = async () => {
    let response = await contactModel.getCustomers();
    let contacts: contactModel.IContact[] = response.data.contacts;
    setContacts(contacts);
    setIsloading(false);
  };

  const getBaseRate = (contact: contactModel.IContact) => {
    let payrollAmount =
      ((contact.rate - contact.serviceFee) * contact.payrollFee) / 100;
    let baseRate = contact.rate - contact.serviceFee - payrollAmount;
    return baseRate;
  };

  const getFees = (contact: contactModel.IContact) => {
    let payrollAmount =
      ((contact.rate - contact.serviceFee) * contact.payrollFee) / 100;
    let fees =
      parseFloat(contact.serviceFee.toString()) +
      parseFloat(payrollAmount.toString());
    return fees;
  };

  const getMonthlyApprox = (contact: contactModel.IContact) => {
    let fees = getFees(contact);
    let returnValue = fees * 160;

    totalMonthlyApprox =
      parseFloat(totalMonthlyApprox.toString()) +
      parseFloat(returnValue.toString());
    return returnValue;
  };

  const getAnnualApprox = (contact: contactModel.IContact) => {
    let fees = getFees(contact);
    let returnValue = fees * 160 * 12;
    totalAnnualApprox =
      parseFloat(totalAnnualApprox.toString()) +
      parseFloat(returnValue.toString());

    return returnValue;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Finances | Acader Contract Management System</title>
      </Helmet>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>Administrator's layer</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <NavTabs route="finance" />
                <h2 className="mt-3">Finances</h2>
                <hr />
                <div className="table-responsive">
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Invoice reference</th>
                        <th>Contract number</th>
                        <th>Rate</th>
                        <th>Rate to client</th>
                        <th>Different</th>
                        <th>Month earning (approx)</th>
                        <th>Annual earning (approx)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts &&
                        contacts.map((x, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {x.firstName} {x.lastName}
                              </td>
                              <td>{x.invoiceNumberTemplate}</td>
                              <td>{x.clientContract}</td>
                              <td>${getBaseRate(x).toFixed(2)}</td>
                              <td>${x.rate && Number(x.rate).toFixed(2)}</td>
                              <td>${getFees(x).toFixed(2)}</td>
                              <td>${getMonthlyApprox(x).toFixed(2)}</td>
                              <td>${getAnnualApprox(x).toFixed(2)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <thead>
                      <tr>
                        <th colSpan={6}>Total</th>
                        <th>${totalMonthlyApprox.toFixed(2)}</th>
                        <th>${totalAnnualApprox.toFixed(2)}</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
