import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil, faUser } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as accountModel from "../../../models/account";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import { getStatusBadgeColor } from "../../../models/utilities";

export const Accounts = () => {
  const [accounts, setAccounts] = useState<accountModel.IAccount[]>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [newAccount, setNewAccount] = useState<accountModel.IAccount>({
    name: "",
    website: "",
    email: "",
  });
  const [errors, setErrors] = useState<accountModel.IAccount>({});
  const [newAccountIsOpen, setNewAccountIsOpen] = useState<boolean>(false);
  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response = await accountModel.list();
    let accounts: accountModel.IAccount[] = response.data.accounts;
    setAccounts(accounts);
    setIsloading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Accounts | Acader Contract Management System</title>
      </Helmet>

      <Modal
        size="lg"
        isOpen={newAccountIsOpen}
        toggle={() => setNewAccountIsOpen((x) => !x)}
      >
        <ModalHeader>New Account</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="txtName" className="required">
                  Name
                </Label>
                <Input
                  id="txtName"
                  value={newAccount.name}
                  onChange={(e) => {
                    e.persist();
                    setNewAccount((x) => ({
                      ...x,
                      name: e.target.value,
                    }));
                  }}
                />
                {errors.name && (
                  <Label className="text-danger">{errors.name}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtUrl" className="required">
                  Website url
                </Label>
                <Input
                  id="txtUrl"
                  value={newAccount.website}
                  onChange={(e) => {
                    e.persist();
                    setNewAccount((x) => ({
                      ...x,
                      website: e.target.value,
                    }));
                  }}
                />
                {errors.website && (
                  <Label className="text-danger">{errors.website}</Label>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="txtEmail" className="required">
                  Email
                </Label>
                <Input
                  id="txtEmail"
                  type="email"
                  value={newAccount.email}
                  onChange={(e) => {
                    e.persist();
                    setNewAccount((x) => ({
                      ...x,
                      email: e.target.value,
                    }));
                  }}
                />
                {errors.email && (
                  <Label className="text-danger">{errors.email}</Label>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              try {
                await accountModel.accountSchema.validateSync(newAccount, {
                  abortEarly: false,
                });
                await accountModel.create(newAccount);
                window.location.reload();
              } catch (yupErrors) {
                let errors: accountModel.IAccount = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Save
          </Button>
          <Button onClick={() => setNewAccountIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>Accounts</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => setNewAccountIsOpen(true)}
                >
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  Add a new account
                </button>
                <br />
                <br />
                {accounts && (
                  <div className="table-responsive">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {accounts.map((x, i) => {
                          return (
                            <tr key={i}>
                              <td>{x.name}</td>
                              <td>
                                <a href={`mailto:${x.email}`}>{x.email}</a>
                              </td>
                              <td>
                                <Badge color={getStatusBadgeColor(x.status)}>
                                  {x.status}
                                </Badge>
                              </td>
                              <td>
                                <button
                                  className="btn btn-dark"
                                  onClick={() =>
                                    (window.location.href = `/account/${x.id}`)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    className="mr-2"
                                  />
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
