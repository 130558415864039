import React from "react";

import { Nav, NavItem, NavLink, Row } from "reactstrap";

interface NavTabsProps {
  activeTab:
    | "general"
    | "contacts"
    | "documents"
    | "activities"
    | "notes"
    | "rate-breakdown"
    | "opportunities";
  id: string;
  route: "contact" | "account";
}

export const NavTabs = (props: NavTabsProps) => {
  return (
    <>
      <div className="theme theme-white">
        <div className="container">
          <div className="row">
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={props.activeTab === "general"}
                  href={`/${props.route}/${props.id}`}
                >
                  General
                </NavLink>
              </NavItem>
              {props.route === "account" && (
                <NavItem>
                  <NavLink
                    active={props.activeTab === "contacts"}
                    href={`/${props.route}/contacts/${props.id}`}
                  >
                    Contacts
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  active={props.activeTab === "documents"}
                  href={`/${props.route}/documents/${props.id}`}
                >
                  Documents
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  active={props.activeTab === "activities"}
                  href={`/${props.route}/activities/${props.id}`}
                >
                  Activities
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  active={props.activeTab === "notes"}
                  href={`/${props.route}/notes/${props.id}`}
                >
                  Notes
                </NavLink>
              </NavItem>

              {props.route === "contact" && (
                <>
                  <NavItem>
                    <NavLink
                      active={props.activeTab === "opportunities"}
                      href={`/${props.route}/opportunities/${props.id}`}
                    >
                      Opportunities
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      active={props.activeTab === "rate-breakdown"}
                      href={`/${props.route}/rate-breakdown/${props.id}`}
                    >
                      Rate breakdown
                    </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
          </div>
        </div>
      </div>
    </>
  );
};
