import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as auth from "../../models/auth";
import * as yup from "yup";

const loginSchema = yup
  .string()
  .optional()
  .email("Username should be a valid email")
  .required("Username is required")
  .defined("Username is required");

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const handleSendEmail = async () => {
    setErrors({});
    try {
      await loginSchema.validateSync(email, {
        abortEarly: false,
      });
      let emailSent = await auth.forgotPassword(email);
      setEmailSent(emailSent);
    } catch (yupErrors) {
      let errors = {};
      yupErrors.inner.map((x: any) => {
        errors["email"] = x.message;
      });
      setErrors(errors);
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Forgot password | Acader Contract Management System</title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Forgot password</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-white">
        {!emailSent && (
          <Container>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="txtEmail">Please enter your email address</Label>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => {
                      e.persist();
                      setEmail(e.target.value);
                    }}
                  />
                  {errors.email && (
                    <Label className="text-danger">{errors.email}</Label>
                  )}
                </FormGroup>
                <Button color="dark" onClick={handleSendEmail}>
                  Send password change email
                </Button>
              </Col>
            </Row>
          </Container>
        )}
        {emailSent && (
          <Container>
            <Row>
              <Col>
                <Label>Please check your inbox for further instructions</Label>
                <br />
                <Button
                  color="dark"
                  onClick={() => (window.location.href = "/")}
                >
                  Back to login
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};
