import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Input, Label, Row, Table } from "reactstrap";
import { NavTabs } from "../navTabs";
import { list } from "../../../models/variable";
import * as contactModel from "../../../models/contacts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

interface AccountActivitiesProps {
  match: any;
}

export const ContactRateBreakdown = (props: AccountActivitiesProps) => {
  const [contact, setContact] = useState<contactModel.IContact>();
  const [superAnnuation, setSuperAnnuation] = useState<number>();

  const [payrollFeeAmount, setPayrollFeeAmount] = useState<number>(0);
  const [payrollTax, setPayrollTax] = useState<number>(0);
  const [baseRate, setBaseRate] = useState<number>(0);
  const [rateInclusiveSuper, setRateInclusiveSuper] = useState<number>(0);
  const [rateExclusiveSuper, setRateExclusiveSuper] = useState<number>(0);

  useEffect(() => {
    initialise();
    initialiseContact();
  }, []);

  useEffect(() => {
    setRateInclusiveSuper(baseRate - payrollFeeAmount);
  }, [baseRate, payrollFeeAmount]);

  useEffect(() => {
    let payrollFeeAmount =
      contact && contact.payrollFee ? (baseRate * contact.payrollFee) / 100 : 0;
    setPayrollFeeAmount(payrollFeeAmount);
  }, [baseRate]);

  const initialiseContact = async () => {
    let response = await contactModel.get(props.match.params.id);
    let contact: contactModel.IContact = response.data.contact;
    setContact(contact);
    let payrollTaxAmount = (contact.rate * payrollTax) / 100;
    setBaseRate(contact.rate - contact.serviceFee - payrollTaxAmount);
  };

  const initialise = async () => {
    let response: any = await list();
    let superAnnuation = response.data.variables.find(
      (x) => x.name.toLocaleLowerCase() == "superannuation"
    ).value;
    setSuperAnnuation(parseFloat(superAnnuation));
  };

  useEffect(() => {
    let superFactor = superAnnuation / 100 + 1;
    let rateExclusiveSuper = rateInclusiveSuper / superFactor;
    setRateExclusiveSuper(rateExclusiveSuper);
  }, [rateInclusiveSuper, superAnnuation]);
  useEffect(() => {
    if (contact && contact.rate && contact.serviceFee) {
      let payrollTaxAmount = (contact.rate * payrollTax) / 100;
      setBaseRate(contact.rate - contact.serviceFee - payrollTaxAmount);
    }
  }, [payrollTax]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>
          Contact Rate Calculator | Acader Contract Management System
        </title>
      </Helmet>
      {contact && (
        <>
          <div className="theme theme-primary">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h1>
                    {contact.firstName} {contact.lastName}
                  </h1>
                  <div className="h1Divider"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="theme theme-white">
            <Container>
              <Row>
                <Col>
                  <button
                    className="btn btn-dark"
                    onClick={() => (window.location.href = "/contacts")}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                    Contacts
                  </button>
                  <br />
                  <br />
                  <NavTabs
                    activeTab="rate-breakdown"
                    id={contact.id}
                    route="contact"
                  />
                  <h2 className="mt-3">Rate breakdown</h2>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="table-responsive">
                    <Table>
                      <tr>
                        <th>Payroll tax</th>
                        <td>
                          <Input
                            type="number"
                            value={payrollTax}
                            onChange={(e) => {
                              e.persist();
                              setPayrollTax(parseFloat(e.target.value));
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Payroll rate (%)</th>
                        <td>
                          <Label>{contact.payrollFee}</Label>
                        </td>
                      </tr>
                      <tr>
                        <th>Rate to department</th>
                        <td>${contact.rate}</td>
                      </tr>
                      <tr>
                        <th>Contractor base rate</th>
                        <td>${baseRate}</td>
                      </tr>
                      <tr>
                        <th>Contractor rate (Inclusive super)</th>
                        <td>${rateInclusiveSuper}</td>
                      </tr>
                      <tr>
                        <th>Contractor rate (Exclusive super)</th>
                        <td>${rateExclusiveSuper.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>Service fee</th>
                        <td>${contact.serviceFee}</td>
                      </tr>
                      <tr>
                        <th>Payroll fee</th>
                        <td>${payrollFeeAmount}</td>
                      </tr>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};
