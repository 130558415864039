import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import * as accountModel from "../../../models/account";
import Select from "react-dropdown-select";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { NavTabs } from "../navTabs";
import LoadingOverlay from "react-loading-overlay-ts";
import scroll from "react-scroll";

interface AccountProps {
  match: any;
}

interface Tags {
  label: any;
  value;
}

export const Account = (props: AccountProps) => {
  const didMount = useRef(false);
  const [account, setAccount] = useState<accountModel.IAccount>({ tags: "" });
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [tags, setTags] = useState<Tags[]>();
  const [interestedIsOpen, setInterestedIsOpen] = useState<boolean>(false);
  const [interested, setInterested] = useState<string>("Yes");
  const [resultIsOpen, setResultIsOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<accountModel.IAccount>({});
  const [result, setResult] = useState<string>("Yes");
  useEffect(() => {
    initialise();
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    saveContact();
  }, [account.status]);

  const saveContact = async () => {
    await accountModel.update(account);
  };

  const getLifeCycleStage = (status: string) => {
    let lifeCycleStage: string;
    switch (status) {
      case "New":
      case "Contacted":
      case "Not Interested":
        lifeCycleStage = "Lead";
        break;
      case "Interested":
      case "Qualified":
      case "Negotiation":
      case "Lost":
        lifeCycleStage = "Sales Qualified Lead";
        break;
      case "Won":
        lifeCycleStage = "Customer";
        break;
      default:
        lifeCycleStage = "Lead";
        break;
    }
    return lifeCycleStage;
  };

  const initialise = async () => {
    let response = await accountModel.get(props.match.params.id);
    let account: accountModel.IAccount = response.data.account;
    let tags: Tags[] = [];
    account.tags &&
      account.tags.split(";").map((x) => {
        tags.push({ label: x, value: x });
      });
    setTags(tags);
    setAccount(account);
    setIsloading(false);
  };

  const customNoDataRenderer = () => {
    return <></>;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Account | Acader Contract Management System</title>
      </Helmet>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>{account && <>{account.name}</>}</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => (window.location.href = "/accounts")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                  Accounts
                </button>
                <br />
                <br />
                {account && (
                  <>
                    <Modal
                      isOpen={interestedIsOpen}
                      toggle={() => setInterestedIsOpen((x) => !x)}
                    >
                      <ModalHeader>Interested?</ModalHeader>
                      <ModalBody>
                        <Label>Is the lead interested to proceed?</Label>
                        <select
                          className="form-control"
                          defaultValue={interested}
                          onChange={(e) => setInterested(e.currentTarget.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="success"
                          onClick={() => {
                            let status =
                              interested === "Yes"
                                ? "Interested"
                                : "Not Interested";
                            setAccount((x) => ({
                              ...x,
                              status: status,
                              lifeCycleStage: getLifeCycleStage(status),
                            }));
                            window.location.reload();
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} className="mr-2" />
                          Save status
                        </Button>
                        <Button onClick={() => setInterestedIsOpen(false)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <Modal
                      isOpen={resultIsOpen}
                      toggle={() => setResultIsOpen((x) => !x)}
                    >
                      <ModalHeader>Result?</ModalHeader>
                      <ModalBody>
                        <Label>
                          Has this lead successfully converted to a Customer?
                        </Label>
                        <select
                          className="form-control"
                          defaultValue={interested}
                          onChange={(e) => setResult(e.currentTarget.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="success"
                          onClick={() => {
                            let status = result === "Yes" ? "Won" : "Lost";
                            setAccount((x) => ({
                              ...x,
                              status: status,
                              lifeCycleStage: getLifeCycleStage(status),
                            }));
                            window.location.reload();
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} className="mr-2" />
                          Save status
                        </Button>
                        <Button onClick={() => setResultIsOpen(false)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <NavTabs
                      activeTab="general"
                      id={account.id}
                      route="account"
                    />
                    <h2 className="mt-3">General</h2>
                    <hr />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Status</Label>
                          <div className="arrow-steps clearfix mb-4">
                            <div
                              className={`step ${
                                account.status === "New" && "current"
                              }`}
                              onClick={() => {
                                setAccount((x) => ({
                                  ...x,
                                  status: "New",
                                  lifeCycleStage: getLifeCycleStage("New"),
                                }));
                              }}
                            >
                              <span>New</span>
                            </div>
                            <div
                              className={`step ${
                                account.status === "Contacted" && "current"
                              }`}
                              onClick={() => {
                                setAccount((x) => ({
                                  ...x,
                                  status: "Contacted",
                                  lifeCycleStage:
                                    getLifeCycleStage("Contacted"),
                                }));
                              }}
                            >
                              <span>Contacted</span>
                            </div>
                            <div
                              className={`step ${
                                (account.status === "Interested" &&
                                  "current") ||
                                (account.status === "Not Interested" &&
                                  "blocked")
                              }`}
                              onClick={() => setInterestedIsOpen(true)}
                            >
                              <span>
                                {account.status === "Interested" ||
                                account.status === "Not Interested"
                                  ? account.status
                                  : "Interested?"}
                              </span>
                            </div>
                            <div
                              className={`step ${
                                account.status === "Negotiation" && "current"
                              }`}
                              onClick={() => {
                                setAccount((x) => ({
                                  ...x,
                                  status: "Negotiation",
                                  lifeCycleStage:
                                    getLifeCycleStage("Negotiation"),
                                }));
                              }}
                            >
                              <span>Negotiation</span>
                            </div>
                            <div
                              className={`step ${
                                (account.status === "Won" && "won") ||
                                (account.status === "Lost" && "blocked")
                              }`}
                              onClick={() => setResultIsOpen(true)}
                            >
                              <span>
                                {account.status === "Won" ||
                                account.status === "Lost"
                                  ? account.status
                                  : "Result?"}
                              </span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label>Lead status</Label>
                          <Input readOnly value={account.lifeCycleStage} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtName" className="required">
                            Name
                          </Label>
                          <Input
                            id="txtName"
                            type="text"
                            value={account.name}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                name: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.name && (
                            <Label className="text-danger">{errors.name}</Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtWebsite" className="required">
                            Website
                          </Label>
                          <Input
                            id="txtWebsite"
                            value={account.website}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                website: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.website && (
                            <Label className="text-danger">
                              {errors.website}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtLinkedIn">LinkedIn Url</Label>
                          <Input
                            id="txtLinkedIn"
                            type="url"
                            value={account.linkedinProfile}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                linkedinProfile: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="txtClientContract">
                            Client Contract Number
                          </Label>
                          <Input
                            id="txtClientContract"
                            type="url"
                            value={account.clientContract}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                clientContract: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtEmail" className="required">
                            Email
                          </Label>
                          <Input
                            id="txtEmail"
                            value={account.email}
                            type="email"
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                email: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.email && (
                            <Label className="text-danger">
                              {errors.email}
                            </Label>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtEmail2">Email 2</Label>
                          <Input
                            id="txtEmail2"
                            value={account.email2}
                            type="email"
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                email2: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtTags">Tags</Label>
                          <Select<Tags>
                            className="form-control"
                            multi
                            create
                            options={[]}
                            values={tags}
                            onChange={(tags) => {
                              setAccount((x) => ({
                                ...x,
                                tags: tags.map((x: any) => x.value).join(";"),
                              }));
                            }}
                            placeholder="type a tag and press enter"
                            noDataRenderer={customNoDataRenderer}
                            pattern="^[0-9]*$"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="txtInternalContract">
                            Internal reference number
                          </Label>
                          <Input
                            id="txtInternalContract"
                            type="url"
                            value={account.internalReferenceNumber}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                internalReferenceNumber: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <h2>Address</h2>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtAddressLine1">Address line 1</Label>
                          <Input
                            id="txtAddressLine1"
                            value={account.addressLine1}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                addressLine1: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtAddressLine2">Address line 2</Label>
                          <Input
                            id="txtAddressLine2"
                            value={account.addressLine2}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                addressLine2: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtAddressLine3">Address line 3</Label>
                          <Input
                            id="txtAddressLine3"
                            value={account.addressLine3}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                addressLine3: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtCity">City</Label>
                          <Input
                            id="txtCity"
                            value={account.city}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                city: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtSuburb">Suburb</Label>
                          <Input
                            id="txtSuburb"
                            value={account.suburb}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                suburb: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>

                        <FormGroup>
                          <Label for="txtPostcode">Postcode</Label>
                          <Input
                            id="txtPostcode"
                            value={account.postCode}
                            onChange={(e) => {
                              e.persist();
                              setAccount((x) => ({
                                ...x,
                                postCode: e.target.value,
                              }));
                            }}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Button
                          className="btn-dark"
                          onClick={async () => {
                            try {
                              await accountModel.accountSchema.validateSync(
                                account,
                                {
                                  abortEarly: false,
                                }
                              );
                              await accountModel.update(account);
                              window.location.reload();
                            } catch (yupErrors) {
                              let errors: accountModel.IAccount = {};
                              yupErrors.inner.map((x: any) => {
                                errors[x.path] = x.message;
                              });
                              setErrors(errors);
                              scroll.scroller.scrollTo("text-danger", {
                                duration: 800,
                                delay: 0,
                              });
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faSave} className="mr-2" />
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
