import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import * as jobModel from "../../../models/job";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Container,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DatePicker from "react-datepicker";
import enAu from "date-fns/locale/en-AU";
import scroll from "react-scroll";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useDropzone } from "react-dropzone";

interface ContactProps {
  match: any;
}

export const Job = (props: ContactProps) => {
  const didMount = useRef(false);
  const [job, setJob] = useState<jobModel.IJob>({});
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [errors, setErrors] = useState<jobModel.IJob>({});
  const [overviewState, setOverviewState] = useState(() =>
    EditorState.createEmpty()
  );

  const [essentialState, setEssentialState] = useState(() =>
    EditorState.createEmpty()
  );

  const [desirableState, setDesirableState] = useState(() =>
    EditorState.createEmpty()
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => uploadFiles(files),
  });

  const uploadFiles = async (files: File[]) => {
    await jobModel.uploadDocument(files, job.id);
    window.location.reload();
  };

  useEffect(() => {
    if (props.match.params.id !== "0") {
      initialise();
    } else {
      setIsloading(false);
    }
  }, []);

  useEffect(() => {
    console.log(job);
  }, [job.overview]);

  const handleUpdate = async () => {
    try {
      await jobModel.jobSchema.validateSync(job, {
        abortEarly: false,
      });
      if (props.match.params.id !== "0") {
        await jobModel.update(job);
        window.location.reload();
      } else {
        await jobModel.create(job);
        window.location.href = "/jobs";
      }
    } catch (yupErrors) {
      let errors: jobModel.IJob = {};
      console.log(yupErrors.inner);
      yupErrors.inner.map((x: any) => {
        errors[x.path] = x.message;
      });
      setErrors(errors);
      scroll.scroller.scrollTo("text-danger", {
        duration: 800,
        delay: 0,
      });
    }
  };

  const initialise = async () => {
    let response = await jobModel.get(props.match.params.id);
    let job: jobModel.IJob = response.data.job;

    const overviewBlocks = convertFromHTML(job.overview);
    let overciewValue = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        overviewBlocks.contentBlocks,
        overviewBlocks.entityMap
      )
    );
    setOverviewState(overciewValue);

    const essentialBlocks = convertFromHTML(job.essentialCriteria);
    let essentialValue = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        essentialBlocks.contentBlocks,
        essentialBlocks.entityMap
      )
    );
    setEssentialState(essentialValue);

    const desirableBlocks = convertFromHTML(job.desirableCriteria);
    let desirableValue = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        desirableBlocks.contentBlocks,
        desirableBlocks.entityMap
      )
    );
    setDesirableState(desirableValue);

    setJob(job);
    setIsloading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Job | Acader Contract Management System</title>
      </Helmet>

      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="header-h1">
                  {job && job.opportunityId && (
                    <>
                      {job.opportunityId}-{job.title}
                    </>
                  )}

                  {(!job || !job.opportunityId || !job.title) && <>New Job</>}
                </h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="txtOpportunityId" className="required">
                    Opportunity Id
                  </Label>
                  <Input
                    autoComplete="off"
                    id="txtOpportunityId"
                    value={job.opportunityId}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        opportunityId: e.target.value,
                      }));
                    }}
                  ></Input>
                  {errors.opportunityId && (
                    <Label className="text-danger">
                      {errors.opportunityId}
                    </Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="txtJobTitle" className="required">
                    Job title
                  </Label>
                  <Input
                    autoComplete="off"
                    id="txtJobTitle"
                    value={job.title}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        title: e.target.value,
                      }));
                    }}
                  ></Input>
                  {errors.title && (
                    <Label className="text-danger">{errors.title}</Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="txtDepartmentName" className="required">
                    Name of the Department
                  </Label>
                  <Input
                    autoComplete="off"
                    id="txtDepartmentName"
                    value={job.departmentName}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        departmentName: e.target.value,
                      }));
                    }}
                  ></Input>
                  {errors.departmentName && (
                    <Label className="text-danger">
                      {errors.departmentName}
                    </Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="dtDeadline" className="required">
                    Deadline
                  </Label>
                  <DatePicker
                    id="dtDeadline"
                    autoComplete="off"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    locale={enAu}
                    selected={job.deadline && new Date(job.deadline)}
                    onChange={(date: Date) =>
                      setJob((x) => ({
                        ...x,
                        deadline: date,
                      }))
                    }
                  />
                  {errors.deadline && (
                    <Label className="text-danger">{errors.deadline}</Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="txtClearance" className="required">
                    Clearance level required
                  </Label>
                  <Input
                    autoComplete="off"
                    id="txtClearance"
                    value={job.clearance}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        clearance: e.target.value,
                      }));
                    }}
                  ></Input>
                  {errors.clearance && (
                    <Label className="text-danger">{errors.clearance}</Label>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="txtLocationOfWork" className="required">
                    Location of work
                  </Label>
                  <Input
                    autoComplete="off"
                    id="txtLocationOfWork"
                    value={job.locationOfWork}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        locationOfWork: e.target.value,
                      }));
                    }}
                  ></Input>
                  {errors.locationOfWork && (
                    <Label className="text-danger">
                      {errors.locationOfWork}
                    </Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="txtContractDuration" className="required">
                    Duration of contract
                  </Label>
                  <Input
                    autoComplete="off"
                    id="txtContractDuration"
                    value={job.contractDuration}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        contractDuration: e.target.value,
                      }));
                    }}
                  ></Input>
                  {errors.contractDuration && (
                    <Label className="text-danger">
                      {errors.contractDuration}
                    </Label>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="txtExtensionOptions" className="required">
                    Extension Options
                  </Label>
                  <Input
                    autoComplete="off"
                    id="txtExtensionOptions"
                    value={job.extentionOptions}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        extentionOptions: e.target.value,
                      }));
                    }}
                  ></Input>
                  {errors.extentionOptions && (
                    <Label className="text-danger">
                      {errors.extentionOptions}
                    </Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="dtExpectedStartDate" className="required">
                    Expected Start Date
                  </Label>
                  <DatePicker
                    id="dtExpectedStartDate"
                    autoComplete="off"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    locale={enAu}
                    selected={
                      job.expectedStartDate && new Date(job.expectedStartDate)
                    }
                    onChange={(date: Date) =>
                      setJob((x) => ({
                        ...x,
                        expectedStartDate: date,
                      }))
                    }
                  />
                  {errors.expectedStartDate && (
                    <Label className="text-danger">
                      {errors.expectedStartDate}
                    </Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="txtSpent">Spent</Label>
                  <Input
                    type="number"
                    autoComplete="off"
                    id="txtSpent"
                    value={job.spent}
                    onChange={(e) => {
                      e.persist();
                      setJob((x) => ({
                        ...x,
                        spent:
                          e.target.value &&
                          e.target.value !== "" &&
                          parseFloat(e.target.value),
                      }));
                    }}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {props.match.params.id !== "0" && (
                  <FormGroup>
                    <Label>
                      Selection criteria PDF
                      {job.scData && (
                        <Button
                          className="btn-dark ml-2"
                          onClick={() => {
                            jobModel.download(
                              job.id,
                              job.opportunityId,
                              job.title
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} className="mr-2" />
                          Download
                        </Button>
                      )}
                    </Label>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' Drop files here, or click to select files</p>
                    </div>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="required">Overview</Label>
                  <Editor
                    editorState={overviewState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={(e) => {
                      setJob((x) => ({
                        ...x,
                        overview: stateToHTML(e.getCurrentContent()),
                      }));
                      setOverviewState(e);
                    }}
                  />
                  {errors.overview && (
                    <Label className="text-danger">{errors.overview}</Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label className="required">Essential Criteria</Label>
                  <Editor
                    editorState={essentialState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={(e) => {
                      setJob((x) => ({
                        ...x,
                        essentialCriteria: stateToHTML(e.getCurrentContent()),
                      }));
                      setEssentialState(e);
                    }}
                  />
                  {errors.essentialCriteria && (
                    <Label className="text-danger">
                      {errors.essentialCriteria}
                    </Label>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label className="required">Desirable Criteria</Label>
                  <Editor
                    editorState={desirableState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={(e) => {
                      setJob((x) => ({
                        ...x,
                        desirableCriteria: stateToHTML(e.getCurrentContent()),
                      }));
                      setDesirableState(e);
                    }}
                  />
                  {errors.desirableCriteria && (
                    <Label className="text-danger">
                      {errors.desirableCriteria}
                    </Label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <Button className="btn-dark" onClick={handleUpdate}>
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  Save
                </Button>

                {props.match.params.id !== "0" && (
                  <Button
                    className="btn-dark ml-2"
                    onClick={async () => {
                      scroll.scroller.scrollTo("header-h1", {
                        duration: 800,
                        delay: 0,
                      });
                      setIsloading(true);
                      await jobModel.build(
                        job.id,
                        job.opportunityId,
                        job.title
                      );
                      setIsloading(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    Build Selection Criteria
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    </>
  );
};
