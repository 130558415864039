import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faDownload,
  faSave,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import * as contactJobModel from "../../../models/contactJob";
import * as systemVariableModel from "../../../models/variable";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import scroll from "react-scroll";

interface ContactJobProps {
  match: any;
}

export const ContactJob = (props: ContactJobProps) => {
  const didMount = useRef(false);
  const [contactJob, setContactJob] = useState<contactJobModel.IContactJob>({});
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [errors, setErrors] = useState<contactJobModel.IContactJob>({});
  const [payrollTax, setPayrollTax] = useState<systemVariableModel.IVariable>(
    {}
  );
  const [summaryState, setSumaryState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    initialise();
  }, []);

  const handleUpdate = async () => {
    try {
      await contactJobModel.contactJobEditSchema.validateSync(contactJob, {
        abortEarly: false,
      });
      await contactJobModel.update(contactJob);
      window.location.reload();
    } catch (yupErrors) {
      let errors: contactJobModel.IContactJob = {};
      yupErrors.inner.map((x: any) => {
        errors[x.path] = x.message;
      });
      setErrors(errors);
      scroll.scroller.scrollTo("text-danger", {
        duration: 800,
        delay: 0,
      });
    }
  };

  const initialise = async () => {
    let response = await contactJobModel.get(props.match.params.id);
    let contactJob: contactJobModel.IContactJob = response.data.contactJob;

    let variableResponse = await systemVariableModel.getByName("PayrollTax");
    let payrollTax: systemVariableModel.IVariable =
      variableResponse.data.variable;

    if (contactJob.summary) {
      const summaryBlocks = convertFromHTML(contactJob.summary);
      let summaryValue = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          summaryBlocks.contentBlocks,
          summaryBlocks.entityMap
        )
      );
      setSumaryState(summaryValue);
    }

    setContactJob(contactJob);
    setPayrollTax(payrollTax);
    setIsloading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Contact Job | Acader Contract Management System</title>
      </Helmet>

      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>
                  {contactJob && contactJob.contact && (
                    <>
                      {contactJob.contact.firstName}{" "}
                      {contactJob.contact.lastName}
                    </>
                  )}
                </h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() =>
                    (window.location.href =
                      "/contact/opportunities/" + contactJob.contact.id)
                  }
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                  Opportunities
                </button>
                <br />
                <br />
                {contactJob && (
                  <>
                    <h2 className="mt-3">
                      {contactJob &&
                        contactJob.job &&
                        contactJob.job.title +
                          " with " +
                          contactJob.job.departmentName +
                          " : " +
                          contactJob.job.opportunityId}
                    </h2>
                    <hr />

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtRate" className="required">
                            Rate
                          </Label>
                          <Input
                            autoComplete="off"
                            type="number"
                            id="txtRate"
                            value={contactJob.rate}
                            onChange={(e) => {
                              e.persist();
                              let rate = parseFloat(e.target.value);
                              let serviceFee = parseFloat(
                                contactJob.serviceFee
                              );
                              let payrollTaxValue = parseFloat(
                                payrollTax.value
                              );
                              let payrollFee =
                                (rate + serviceFee) * (payrollTaxValue / 100);
                              setContactJob((x) => ({
                                ...x,
                                rate: e.target.value,
                                payrollFee: payrollFee.toFixed(2),
                              }));
                            }}
                          ></Input>
                          {errors.rate && (
                            <Label className="text-danger">{errors.rate}</Label>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtNoticePeriod" className="required">
                            Notice Period
                          </Label>
                          <Input
                            id="txtNoticePeriod"
                            type="text"
                            autoComplete="off"
                            value={contactJob.noticePeriod}
                            onChange={(e) => {
                              e.persist();
                              setContactJob((x) => ({
                                ...x,
                                noticePeriod: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.noticePeriod && (
                            <Label className="text-danger">
                              {errors.noticePeriod}
                            </Label>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtServiceFee" className="required">
                            Service fee
                          </Label>
                          <Input
                            autoComplete="off"
                            type="number"
                            id="txtServiceFee"
                            value={contactJob.serviceFee}
                            onChange={(e) => {
                              e.persist();
                              let rate = parseFloat(contactJob.rate);
                              let serviceFee = parseFloat(e.target.value);
                              let payrollTaxValue = parseFloat(
                                payrollTax.value
                              );
                              let payrollFee =
                                (rate + serviceFee) * (payrollTaxValue / 100);
                              setContactJob((x) => ({
                                ...x,
                                serviceFee: e.target.value,
                                payrollFee: payrollFee.toFixed(2),
                              }));
                            }}
                          ></Input>
                          {errors.serviceFee && (
                            <Label className="text-danger">
                              {errors.serviceFee}
                            </Label>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="txtPayrollTax" className="required">
                            Payroll Fee
                          </Label>
                          <Input
                            readOnly
                            id="txtPayrollTax"
                            type="number"
                            autoComplete="off"
                            value={contactJob.payrollFee}
                          ></Input>
                          {errors.payrollFee && (
                            <Label className="text-danger">
                              {errors.payrollFee}
                            </Label>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="ddlApplied">Applied</Label>
                          <select
                            className="form-control"
                            id="ddlApplied"
                            value={contactJob.applied ? "Yes" : "No"}
                            onChange={(e) => {
                              e.persist();
                              setContactJob((x) => ({
                                ...x,
                                applied:
                                  e.target.value === "Yes" ? true : false,
                              }));
                            }}
                          >
                            <option value="">Please select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="ddlReminded">Reminded</Label>
                          <select
                            className="form-control"
                            id="ddlReminded"
                            value={contactJob.reminded ? "Yes" : "No"}
                            onChange={(e) => {
                              e.persist();
                              setContactJob((x) => ({
                                ...x,
                                reminded:
                                  e.target.value === "Yes" ? true : false,
                              }));
                            }}
                          >
                            <option value="">Please select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="ddlNotInterested">Not Interested</Label>
                          <select
                            className="form-control"
                            id="ddlNotInterested"
                            value={contactJob.notInterested ? "Yes" : "No"}
                            onChange={(e) => {
                              e.persist();
                              setContactJob((x) => ({
                                ...x,
                                notInterested:
                                  e.target.value === "Yes" ? true : false,
                              }));
                            }}
                          >
                            <option value="">Please select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Covers</Label> <br />
                          {!contactJob.resumeCover &&
                            !contactJob.criteriaCover && (
                              <b>
                                Click on the Build button to generate templates
                              </b>
                            )}
                          {contactJob.resumeCover && (
                            <Button
                              className="btn-dark"
                              onClick={() => {
                                contactJobModel.downloadResumeCover(
                                  contactJob.id,
                                  contactJob.contact.firstName
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="mr-2"
                              />
                              Download Resume Cover
                            </Button>
                          )}
                          {contactJob.criteriaCover && (
                            <Button
                              className="btn-dark ml-2"
                              onClick={() => {
                                contactJobModel.downloadCriteriaCover(
                                  contactJob.id,
                                  contactJob.contact.firstName
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="mr-2"
                              />
                              Download Criteria Cover
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="required">Summary</Label>
                          <Editor
                            editorState={summaryState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={(e) => {
                              setContactJob((x) => ({
                                ...x,
                                summary: stateToHTML(e.getCurrentContent()),
                              }));
                              setSumaryState(e);
                            }}
                          />
                          {errors.summary && (
                            <Label className="text-danger">
                              {errors.summary}
                            </Label>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="text-right">
                        <Button className="btn-dark" onClick={handleUpdate}>
                          <FontAwesomeIcon icon={faSave} className="mr-2" />
                          Save
                        </Button>
                        <Button
                          className="btn-dark ml-2"
                          onClick={async () => {
                            setIsloading(true);
                            await contactJobModel.build(contactJob.id);
                            window.location.reload();
                          }}
                        >
                          <FontAwesomeIcon icon={faUpload} className="mr-2" />
                          Build cover
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
