import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faDownload,
  faPencil,
  faTimes,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { IDocument } from "../../models/document";
import * as documentModel from "../../models/document";
import * as yup from "yup";

interface DocumentActionsProps {
  document: IDocument;
}

const fileNameSchema = yup
  .string()
  .required("File name is required")
  .defined("File name is required");

export const DocumentActions = (props: DocumentActionsProps) => {
  const [actionOpen, setActionOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [editIsOpen, setEditIsOpen] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<IDocument>(props.document);
  const [errors, setErrors] = useState<any>({});

  const deleteDocument = async () => {
    await documentModel.deleteOne(props.document.id);
    window.location.reload();
  };

  return (
    <>
      <Modal
        isOpen={confirmDelete}
        toggle={() => setConfirmDelete(!confirmDelete)}
      >
        <ModalHeader>Delete document</ModalHeader>
        <ModalBody>Are you sure you want to delete this document?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteDocument()}>
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Yes, Delete
          </Button>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={editIsOpen} toggle={() => setEditIsOpen((x) => !x)}>
        <ModalHeader>Edit document</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="txtFileName" className="required">
              File name
            </Label>
            <Input
              id="txtFileName"
              value={attachment.fileName}
              onChange={(e) => {
                e.persist();
                setAttachment((x) => ({
                  ...x,
                  fileName: e.target.value,
                }));
              }}
            />
            {errors.fileName && (
              <Label className="text-danger">{errors.fileName}</Label>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="txtFileType" className="required">
              Type
            </Label>
            <select
              className="form-control"
              defaultValue={props.document.type}
              onChange={(e) => {
                e.persist();
                setAttachment((x) => ({
                  ...x,
                  type: e.target.value,
                }));
              }}
            >
              <option value="Resume">Resume</option>
              <option value="Supporting Documents">Supporting Documents</option>
              <option value="Client Contract">Client Contract</option>
              <option value="Internal Contract">Internal Contract</option>
              <option value="Police Check">Police Check</option>
              <option value="Selection Criteria">Selection Criteria</option>
            </select>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="dark"
            onClick={async () => {
              setErrors({});
              try {
                await fileNameSchema.validateSync(attachment.fileName, {
                  abortEarly: false,
                });
                await documentModel.update(attachment);
                window.location.reload();
              } catch (yupErrors) {
                let errors = {};
                yupErrors.inner.map((x: any) => {
                  errors["fileName"] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save
          </Button>
          <Button onClick={() => setEditIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Dropdown isOpen={actionOpen} toggle={() => setActionOpen((x) => !x)}>
        <DropdownToggle caret>
          <FontAwesomeIcon icon={faCog} className="mr-2" />
          Actions
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => documentModel.download(props.document)}>
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            Download
          </DropdownItem>
          <DropdownItem onClick={() => setEditIsOpen(true)}>
            <FontAwesomeIcon icon={faPencil} className="mr-2" />
            Edit
          </DropdownItem>
          <DropdownItem onClick={() => setConfirmDelete(true)}>
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
