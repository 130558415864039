import axios from "axios";
import { IUser } from "./user";

var crypto = require("crypto"),
  algorithm = process.env.REACT_APP_ALGO,
  password = process.env.REACT_APP_PASS;

export let encrypt = (text: string) => {
  var cipher = crypto.createCipher(algorithm, password);
  var crypted = cipher.update(text, "utf8", "hex");
  crypted += cipher.final("hex");
  return crypted;
};

export interface ILogin {
  email?: string;
  password?: string;
}

export let logout = () => {
  axios
    .post(
      process.env.REACT_APP_API_URL + "/logout",
      {},
      { withCredentials: true }
    )
    .then((res: any) => {
      window.location.href = "/";
    })
    .catch((error) => {
      console.log(error);
    });
};

export let login = async (username: string, password: string) => {
  try {
    let response = await axios.post(
      process.env.REACT_APP_API_URL + "/login",
      {
        email: username,
        password: password,
      },
      { withCredentials: true }
    );
    let authenticated = await authenticate();
    if (authenticated) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export let forgotPassword = async (email: string) => {
  try {
    let response = await axios.post(
      process.env.REACT_APP_API_URL + "/forgot-password",
      {
        email: email,
      },
      { withCredentials: true }
    );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export let validateToken = async (token: string, authParam: string) => {
  let response = await axios.post(
    process.env.REACT_APP_API_URL + "/validate-token",
    {
      token: token,
      auth: authParam,
    },
    { withCredentials: true }
  );
  let success: boolean = response.data.success;
  return success;
};

export let resetPassword = async (auth: string, password: string) => {
  let response = await axios.post(
    process.env.REACT_APP_API_URL + "/reset-password",
    {
      auth: auth,
      password: encrypt(password),
    },
    { withCredentials: true }
  );
  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export let changePassword = async (email: string, password: string) => {
  let response = await axios.post(
    process.env.REACT_APP_API_URL + "/change-password",
    {
      email: email,
      password: encrypt(password),
    },
    { withCredentials: true }
  );
  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export let authenticate = async () => {
  try {
    let authResponse = await axios.post(
      process.env.REACT_APP_API_URL + "/validateJwt",
      {},
      { withCredentials: true }
    );
    window.$user = authResponse.data.user;
    return authResponse.data.user;
  } catch (err) {
    return null;
  }
};

export let userIsAdmin = (user: IUser) => {
  let admin = user.roles.find((x) => x.name === "Administrator");
  if (admin) {
    return true;
  } else {
    return false;
  }
};
