import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as auth from "../../models/auth";
import * as yup from "yup";

interface ResetPasswordProps {
  match: any;
}

const passwordSchema = yup
  .string()
  .required("Password is required")
  .defined("Password is required");

export const ResetPassword = (props: ResetPasswordProps) => {
  const [errorHidden, setErrorHidden] = useState<boolean>(true);
  const [password, setPassword] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [errors, setErrors] = useState<any>({});

  const validateToken = async (token: string, authParam: string) => {
    let tokenIsValid = await auth.validateToken(token, authParam);
    if (tokenIsValid) {
      setErrorHidden(true);
      setEmail(email);
    } else {
      setErrorHidden(false);
    }
  };

  const changePassword = async () => {
    setErrors({});
    try {
      await passwordSchema.validateSync(password, {
        abortEarly: false,
      });
      let queryParams = new URLSearchParams(window.location.search);
      let authParam = queryParams.get("auth");
      let success = await auth.resetPassword(authParam, password);
      if (success) {
        window.location.href = "/";
      }
    } catch (yupErrors) {
      let errors = {};
      yupErrors.inner.map((x: any) => {
        errors["password"] = x.message;
      });
      setErrors(errors);
    }
  };

  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let token = queryParams.get("token");
    let auth = queryParams.get("auth");
    validateToken(token, auth);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Reset password | Acader Contract Management System</title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Reset password</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-white">
        {errorHidden && (
          <Container>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="txtPassword">
                    Please enter your new password
                  </Label>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => {
                      e.persist();
                      setPassword(e.target.value);
                    }}
                  />
                  {errors.password && (
                    <Label className="text-danger">{errors.password}</Label>
                  )}
                </FormGroup>
                <Button color="dark" onClick={changePassword}>
                  Change password
                </Button>
              </Col>
            </Row>
          </Container>
        )}
        {!errorHidden && (
          <Container>
            <Row>
              <Col>
                <Alert color="danger">Your token is invalid!</Alert>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};
