import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faPencil,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as contactModel from "../../../models/contacts";
import * as accountModel from "../../../models/account";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from "reactstrap";
import { NavTabs } from "../navTabs";
import LoadingOverlay from "react-loading-overlay-ts";

interface AccountContactsProps {
  match: any;
}

export const AccountContacts = (props: AccountContactsProps) => {
  const [account, setAccount] = useState<accountModel.IAccount>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<contactModel.IContact[]>();
  const [newContact, setNewContact] = useState<contactModel.IContact>({
    firstName: "",
    lastName: "",
    preferredName: "",
    jobTitle: "",
    email: "",
    mobile: "",
    contactType: "Client",
  });
  const [errors, setErrors] = useState<contactModel.IContact>({});
  const [newContactIsOpen, setNewContactIsOpen] = useState<boolean>(false);
  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let accountResponse = await accountModel.get(props.match.params.id);
    let account: accountModel.IAccount = accountResponse.data.account;
    setAccount(account);
    setNewContact((x) => ({ ...x, accountId: account.id }));

    let response = await contactModel.listForAccount(
      props.match.params.id,
      "Client"
    );
    let contacts: contactModel.IContact[] = response.data.contacts;
    setContacts(contacts);
    setIsloading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Contacts for account | Acader Contract Management System</title>
      </Helmet>

      <Modal
        size="lg"
        isOpen={newContactIsOpen}
        toggle={() => setNewContactIsOpen((x) => !x)}
      >
        <ModalHeader>New Contact</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="txtJobTitle" className="required">
                  Job title
                </Label>
                <Input
                  id="txtFirstName"
                  value={newContact.jobTitle}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      jobTitle: e.target.value,
                    }));
                  }}
                />
                {errors.jobTitle && (
                  <Label className="text-danger">{errors.jobTitle}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtFirstName" className="required">
                  First name
                </Label>
                <Input
                  id="txtFirstName"
                  value={newContact.firstName}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      firstName: e.target.value,
                    }));
                  }}
                />
                {errors.firstName && (
                  <Label className="text-danger">{errors.firstName}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtLastName" className="required">
                  Last name
                </Label>
                <Input
                  id="txtLastName"
                  value={newContact.lastName}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      lastName: e.target.value,
                    }));
                  }}
                />
                {errors.lastName && (
                  <Label className="text-danger">{errors.lastName}</Label>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="txtPreferredName" className="required">
                  Preferred name
                </Label>
                <Input
                  id="txtPreferredName"
                  value={newContact.preferredName}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      preferredName: e.target.value,
                    }));
                  }}
                />
                {errors.preferredName && (
                  <Label className="text-danger">{errors.preferredName}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtEmail" className="required">
                  Email
                </Label>
                <Input
                  id="txtEmail"
                  type="email"
                  value={newContact.email}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      email: e.target.value,
                    }));
                  }}
                />
                {errors.email && (
                  <Label className="text-danger">{errors.email}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtMobile" className="required">
                  Mobile
                </Label>
                <Input
                  id="txtMobile"
                  value={newContact.mobile}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      mobile: e.target.value,
                    }));
                  }}
                />
                {errors.mobile && (
                  <Label className="text-danger">{errors.mobile}</Label>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              setErrors({});
              try {
                await contactModel.contactSchema.validateSync(newContact, {
                  abortEarly: false,
                });
                await contactModel.create(newContact);
                window.location.reload();
              } catch (yupErrors) {
                let errors: contactModel.IContact = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Save
          </Button>
          <Button onClick={() => setNewContactIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>{account && <>{account.name}</>}</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => (window.location.href = "/accounts")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                  Accounts
                </button>
                <br />
                <br />

                {account && (
                  <NavTabs
                    activeTab="contacts"
                    id={account.id}
                    route="account"
                  />
                )}
                <h2 className="mt-3">Contacts</h2>
                <hr />
                <Nav tabs className="mb-3">
                  <NavItem>
                    <NavLink
                      active
                      href={`/account/contacts/${props.match.params.id}`}
                    >
                      Clients
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={`/account/consultants/${props.match.params.id}`}
                    >
                      Consultants
                    </NavLink>
                  </NavItem>
                </Nav>
                <button
                  className="btn btn-dark"
                  onClick={() => setNewContactIsOpen(true)}
                >
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  Add a new client
                </button>
                <br />
                <br />
                {contacts && (
                  <div className="table-responsive">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>First name</th>
                          <th>Last name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts.map((x, i) => {
                          return (
                            <tr key={i}>
                              <td>{x.firstName}</td>
                              <td>{x.lastName}</td>
                              <td>{x.email}</td>
                              <td>{x.mobile}</td>
                              <td>
                                <button
                                  className="btn btn-dark"
                                  onClick={() =>
                                    (window.location.href = `/contact/${x.id}`)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    className="mr-2"
                                  />
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
