import axios from "axios";
import * as yup from "yup";

export const userSchema = yup.object().shape({
  firstName: yup.string().required("First name is required").defined(),
  lastName: yup.string().required("Last name is required").defined(),
  email: yup
    .string()
    .email("Email should be a valid email")
    .required("Email is required")
    .defined(),
  position: yup.string().required("Position is required").defined(),
  phone: yup.string().required("Phone is required").defined(),
  roles: yup
    .array()
    .min(1, "Please select at least one role")
    .required("Please select at least one role"),
});

export interface IUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  position?: string;
  phone?: string;
  roles?: IRole[];
  modifiedBy?: string;
  createdBy?: string;
}

export interface IRole {
  name: string;
  modifiedBy?: string;
  createdBy?: string;
}

export const initUser = {
  firstName: "",
  lastName: "",
  email: "",
  position: "",
  phone: "",
  roles: [],
};

export let list = async () => {
  try {
    let response: any = await axios.get(
      process.env.REACT_APP_API_URL + "/user/list",
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export let getRoles = async () => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/roles",
    {
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (user: IUser) => {
  user.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/user/update",
    { data: user },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let create = async (user: IUser) => {
  user.modifiedBy = window.$user.id;
  user.createdBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/user/create",
    user,
    {
      withCredentials: true,
    }
  );
  return response;
};

export let deleteOne = async (userId: string) => {
  let response: any = await axios.delete(
    process.env.REACT_APP_API_URL + "/user/destroy",
    {
      params: { id: userId },
      withCredentials: true,
    }
  );
  return response;
};
