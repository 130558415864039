import axios from "axios";
import {  IUser } from "./user";

export interface IVariable {
  id?: string;
  name?: string;
  value?: string;
  modifiedBy?: string;
  createdBy?: string;
  updatedAt?: string;
  ModifiedByUser?: IUser;
}

export let list = async () => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/variable/list",
    {
      withCredentials: true,
    }
  );
  return response;
};

export let get = async (id: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/variable/get",
    {
      params: { id: id },
      withCredentials: true,
    }
  );
  return response;
};

export let getByName = async (name: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/variable/getByName",
    {
      params: { name: name },
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (variable: IVariable) => {
  variable.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/variable/update",
    { data: variable },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let deleteOne = async (id: string) => {
  let response: any = await axios.delete(
    process.env.REACT_APP_API_URL + "/variable/destroy",
    {
      params: { id: id },
      withCredentials: true,
    }
  );
  return response;
};
