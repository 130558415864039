import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFilter,
  faPencil,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as contactModel from "../../../models/contacts";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import { getStatusBadgeColor } from "../../../models/utilities";
import Select from "react-dropdown-select";

interface Tags {
  label: any;
  value;
}

const customNoDataRenderer = () => {
  return <></>;
};

export const Contacts = () => {
  const [contacts, setContacts] = useState<contactModel.IContact[]>();
  const [contactsFilter, setContactsFilter] =
    useState<contactModel.IContactFilter>({});
  const [newContact, setNewContact] = useState<contactModel.IContact>({
    firstName: "",
    lastName: "",
    preferredName: "",
    jobTitle: "",
    email: "",
    mobile: "",
    contactType: "Consultant",
    tags: "",
  });

  const [errors, setErrors] = useState<contactModel.IContact>({});
  const [newContactIsOpen, setNewContactIsOpen] = useState<boolean>(false);
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [tags, setTags] = useState<Tags[]>();

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response = await contactModel.list();
    let contacts: contactModel.IContact[] = response.data.contacts;
    setContacts(contacts);
    setIsloading(false);
  };

  const search = async () => {
    setIsloading(true);
    let response = await contactModel.filter(contactsFilter);
    let contacts: contactModel.IContact[] = response.data.contacts;
    setContacts(contacts);
    setIsloading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Contacts | Acader Contract Management System</title>
      </Helmet>

      <Modal
        size="lg"
        isOpen={newContactIsOpen}
        toggle={() => setNewContactIsOpen((x) => !x)}
      >
        <ModalHeader>New Contact</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="txtJobTitle" className="required">
                  Job title
                </Label>
                <Input
                  id="txtJobTitle"
                  autoComplete="off"
                  value={newContact.jobTitle}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      jobTitle: e.target.value,
                    }));
                  }}
                />
                {errors.jobTitle && (
                  <Label className="text-danger">{errors.jobTitle}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtFirstName" className="required">
                  First name
                </Label>
                <Input
                  id="txtFirstName"
                  autoComplete="off"
                  value={newContact.firstName}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      firstName: e.target.value,
                    }));
                  }}
                />
                {errors.firstName && (
                  <Label className="text-danger">{errors.firstName}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtLastName" className="required">
                  Last name
                </Label>
                <Input
                  id="txtLastName"
                  autoComplete="off"
                  value={newContact.lastName}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      lastName: e.target.value,
                    }));
                  }}
                />
                {errors.lastName && (
                  <Label className="text-danger">{errors.lastName}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtTags">Tags</Label>
                <Select<Tags>
                  className="form-control"
                  multi
                  create
                  options={[]}
                  values={tags}
                  onChange={(tags) => {
                    setNewContact((x) => ({
                      ...x,
                      tags: tags.map((x: any) => x.value).join(";"),
                    }));
                  }}
                  placeholder="type a tag and press enter"
                  noDataRenderer={customNoDataRenderer}
                  pattern="^[0-9]*$"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="txtPreferredName" className="required">
                  Preferred name
                </Label>
                <Input
                  id="txtPreferredName"
                  autoComplete="off"
                  value={newContact.preferredName}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      preferredName: e.target.value,
                    }));
                  }}
                />
                {errors.preferredName && (
                  <Label className="text-danger">{errors.preferredName}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtEmail" className="required">
                  Email
                </Label>
                <Input
                  id="txtEmail"
                  autoComplete="off"
                  type="email"
                  value={newContact.email}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      email: e.target.value,
                    }));
                  }}
                />
                {errors.email && (
                  <Label className="text-danger">{errors.email}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="txtMobile" className="required">
                  Mobile
                </Label>
                <Input
                  id="txtMobile"
                  autoComplete="off"
                  value={newContact.mobile}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      mobile: e.target.value,
                    }));
                  }}
                />
                {errors.mobile && (
                  <Label className="text-danger">{errors.mobile}</Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="ddlClearance">Security clearance</Label>
                <select
                  className="form-control"
                  id="ddlClearance"
                  value={newContact.clearance}
                  onChange={(e) => {
                    e.persist();
                    setNewContact((x) => ({
                      ...x,
                      clearance: e.target.value,
                    }));
                  }}
                >
                  <option value="">Please select...</option>
                  <option value="Australian Citizen">Australian Citizen</option>
                  <option value="Baseline">Baseline</option>
                  <option value="Baseline In Progress">
                    Baseline In Progress
                  </option>
                  <option value="Negative vetting 1">Negative vetting 1</option>
                  <option value="Negative vetting 2">Negative vetting 2</option>
                  <option value="Top secret negative vetting">
                    Top secret negative vetting
                  </option>
                  <option value="Top secret positive vetting">
                    Top secret positive vetting
                  </option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              setErrors({});
              try {
                await contactModel.contactSchema.validateSync(newContact, {
                  abortEarly: false,
                });
                await contactModel.create(newContact);
                window.location.reload();
              } catch (yupErrors) {
                let errors: contactModel.IContact = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Save
          </Button>
          <Button onClick={() => setNewContactIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <Container>
            <Row>
              <Col md={8}>
                <h1>Contacts</h1>
                <div className="h1Divider"></div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="theme theme-white">
          <Container>
            <Row className="mb-3">
              <Col>
                <button
                  className="btn btn-dark"
                  onClick={() => setFilterIsOpen((x) => !x)}
                >
                  <FontAwesomeIcon icon={faFilter} className="mr-2" />
                  Filter
                </button>
                <Collapse isOpen={filterIsOpen}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="txtTags">Tags</Label>
                        <Input
                          id="txtTags"
                          autoComplete="off"
                          value={contactsFilter.tags}
                          onChange={(e) => {
                            e.persist();
                            setContactsFilter((x) => ({
                              ...x,
                              tags: e.target.value,
                            }));
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="ddlClearance">Clearance</Label>
                        <select
                          className="form-control"
                          id="ddlClearance"
                          value={contactsFilter.clearance}
                          onChange={(e) => {
                            e.persist();
                            setContactsFilter((x) => ({
                              ...x,
                              clearance: e.target.value,
                            }));
                          }}
                        >
                          <option value=""></option>
                          <option value="Baseline">Baseline</option>
                          <option value="Negative vetting 1">
                            Negative vetting 1
                          </option>
                          <option value="Negative vetting 2">
                            Negative vetting 2
                          </option>
                          <option value="Top secret negative vetting">
                            Top secret negative vetting
                          </option>
                          <option value="Top secret positive vetting">
                            Top secret positive vetting
                          </option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="ddlStatus">Status</Label>
                        <select
                          className="form-control"
                          id="ddlStatus"
                          value={contactsFilter.status}
                          onChange={(e) => {
                            e.persist();
                            setContactsFilter((x) => ({
                              ...x,
                              status: e.target.value,
                            }));
                          }}
                        >
                          <option value=""></option>
                          <option value="New">New</option>
                          <option value="Contacted">Contacted</option>
                          <option value="Interested">Interested</option>
                          <option value="Not Interested">Not Interested</option>
                          <option value="Applied">Applied</option>
                          <option value="Won">Won</option>
                          <option value="Lost">Lost</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="txtFirstName">First name</Label>
                        <Input
                          id="txtFirstName"
                          autoComplete="off"
                          value={contactsFilter.firstName}
                          onChange={(e) => {
                            e.persist();
                            setContactsFilter((x) => ({
                              ...x,
                              firstName: e.target.value,
                            }));
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="txtLastname">Last name</Label>
                        <Input
                          id="txtLastname"
                          autoComplete="off"
                          value={contactsFilter.lastName}
                          onChange={(e) => {
                            e.persist();
                            setContactsFilter((x) => ({
                              ...x,
                              lastName: e.target.value,
                            }));
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col></Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col className="text-right">
                      <button className="btn btn-dark" onClick={() => search()}>
                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                        Search
                      </button>
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="btn btn-dark"
                  onClick={() => setNewContactIsOpen(true)}
                >
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  Add a new contact
                </button>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                {contacts && (
                  <div className="table-responsive">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Position</th>
                          <th>Name</th>
                          <th>Clearance</th>
                          <th>Mobile</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts.map((x, i) => {
                          return (
                            <tr key={i}>
                              <td>{x.jobTitle}</td>
                              <td>
                                {x.firstName} {x.lastName}
                              </td>
                              <td>{x.clearance}</td>
                              <td>
                                <a href={`tel:${x.mobile}`}>{x.mobile}</a>
                              </td>
                              <td>
                                <Badge color={getStatusBadgeColor(x.status)}>
                                  {x.status}
                                </Badge>
                              </td>
                              <td>
                                <button
                                  className="btn btn-dark"
                                  onClick={() =>
                                    (window.location.href = `/contact/${x.id}`)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    className="mr-2"
                                  />
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    </>
  );
};
