import axios from "axios";
import * as yup from "yup";
import { IAccount } from "./account";

export interface IContactFilter {
  tags?: string;
  clearance?: string;
  status?: string;
  firstName?: string;
  lastName?: string;
}

export interface IContact {
  id?: string;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  jobTitle?: string;
  email?: string;
  email2?: string;
  mobile?: string;
  otherPhone?: string;
  tags?: string;
  contactType?: string;
  clearance?: string;
  rate?: number;
  serviceFee?: number;
  payrollFee?: number;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  suburb?: string;
  postCode?: string;
  country?: string;
  status?: string;
  lifeCycleStage?: string;
  linkedinProfile?: string;
  currentContractEndDate?: Date;
  contractStartDate?: Date;
  contractEndDate?: Date;
  invoiceNumberTemplate?: string;
  clientContract?: string;
  internalReferenceNumber?: string;
  accountId?: string;
  modifiedBy?: string;
  createdBy?: string;
  account?: IAccount;
}

export let list = async () => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contact/list",
    {
      withCredentials: true,
    }
  );
  return response;
};

export let listForAccount = async (accountId: string, contactType: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contact/listForAccount",
    {
      params: { accountId: accountId, contactType: contactType },
      withCredentials: true,
    }
  );
  return response;
};

export let get = async (id: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contact/get",
    {
      params: { id: id },
      withCredentials: true,
    }
  );
  return response;
};

export let getCustomers = async () => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contact/customers",
    {
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (contact: IContact) => {
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/contact/update",
    { data: contact },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let filter = async (filter: IContactFilter) => {
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/contact/filter",
    { data: filter },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let create = async (contact: IContact) => {
  contact.createdBy = window.$user.id;
  contact.rate = 0;
  contact.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/contact/create",
    { data: contact },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const contactSchema = yup.object().shape({
  firstName: yup.string().required("First name is required").defined(),
  lastName: yup.string().required("Last name is required").defined(),
  email: yup
    .string()
    .email("Email should be a valid email")
    .required("Email is required")
    .defined(),
  jobTitle: yup.string().required("Job title is required").defined(),
  mobile: yup.string().required("Mobile is required").defined(),
  preferredName: yup.string().required("Preferred name is required").defined(),
  contractEndDate: yup.date().when("contractStartDate", {
    is: (contractStartDate: Date) => contractStartDate !== null,
    then: yup
      .date()
      .nullable()
      .when(
        "contractStartDate",
        (contractStartDate: Date, yup) =>
          contractStartDate &&
          yup.min(
            contractStartDate,
            "Contract end date cannot be before contract start date"
          )
      ),
    otherwise: yup.date().nullable().optional(),
  }),
});
