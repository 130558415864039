import * as React from "react";
import { Alert } from "reactstrap";

export const NotAuthorisedPage = () => {
  return (
    <>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Contract Management System</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-white">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center p-5">
              <Alert color="danger">
                <h1>
                  <i className="fas fa-times mr-2"></i>You do not have
                  permissions to view the requested page.
                </h1>
              </Alert>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
