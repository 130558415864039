import axios from "axios";
import * as yup from "yup";

export interface IAccount {
  id?: string;
  name?: string;
  website?: string;
  email?: string;
  email2?: string;
  tags?: string;
  rate?: number;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  suburb?: string;
  postCode?: string;
  country?: string;
  status?: string;
  lifeCycleStage?: string;
  linkedinProfile?: string;
  clientContract?: string;
  internalReferenceNumber?: string;
  accountId?: string;
  modifiedBy?: string;
  createdBy?: string;
}

export const accountSchema = yup.object().shape({
  name: yup.string().required("Account name is required").defined(),
  website: yup
    .string()
    .url("Please enter a valid website url")
    .required("website url is required")
    .defined(),
  email: yup
    .string()
    .email("Email should be a valid email")
    .required("Email is required")
    .defined(),
});

export let list = async () => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/account/list",
    {
      withCredentials: true,
    }
  );
  return response;
};

export let get = async (id: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/account/get",
    {
      params: { id: id },
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (account: IAccount) => {
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/account/update",
    { data: account },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let create = async (account: IAccount) => {
  account.createdBy = window.$user.id;
  account.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/account/create",
    { data: account },
    {
      withCredentials: true,
    }
  );
  return response;
};
