import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as auth from "../../models/auth";
import * as yup from "yup";
import { Label } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";

const loginSchema: yup.SchemaOf<auth.ILogin> = yup.object().shape({
  email: yup
    .string()
    .email("Username should be a valid email")
    .required("Username is required")
    .defined(),
  password: yup.string().required("Password is required").defined(),
});

export const HomePage = () => {
  const [login, setLogin] = useState<auth.ILogin>({ email: "", password: "" });
  const [errors, setErrors] = useState<auth.ILogin>({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsloading] = useState<boolean>(false);

  useEffect(() => {
    authenticate();
  }, []);

  const authenticate = async () => {
    let success = await auth.authenticate();
    if (success) {
      window.location.href = "/dashboard";
    }
  };

  const handleLogin = async () => {
    setErrorMessage(null);
    setErrors({});
    try {
      await loginSchema.validateSync(login, {
        abortEarly: false,
      });
      setIsloading(true);
      let success = await auth.login(login.email, auth.encrypt(login.password));
      if (!success) {
        setErrorMessage("Invalid username or password, please try again.");
      } else {
        window.location.href = "/dashboard";
      }
      setIsloading(false);
    } catch (yupErrors) {
      let errors: auth.ILogin = { email: "", password: "" };
      yupErrors.inner.map((x: any) => {
        errors[x.path] = x.message;
      });
      setErrors(errors);
      setIsloading(false);
    }
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading...">
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Acader Contract Management System</title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Contract Management System</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="theme theme-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <section className="h-100 gradient-form">
                <div className="container py-5 h-100">
                  <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-7">
                      <div className="card">
                        <div className="row g-0">
                          <div className="col-lg-12">
                            <div className="card-body p-md-5 mx-md-4">
                              <div className="text-center"></div>

                              <form>
                                <h2>Please login to your account</h2>
                                {errorMessage && (
                                  <Label className="text-danger">
                                    {errorMessage}
                                  </Label>
                                )}
                                <div className="form-outline mb-4">
                                  <label className="form-label">Username</label>

                                  <input
                                    type="email"
                                    id="form2Example11"
                                    className="form-control"
                                    placeholder="Email address"
                                    onChange={(e) => {
                                      e.persist();
                                      setLogin((x) => ({
                                        ...x,
                                        email: e.target.value,
                                      }));
                                    }}
                                    value={login.email}
                                  />
                                  {errors.email && (
                                    <Label className="text-danger">
                                      {errors.email}
                                    </Label>
                                  )}
                                </div>
                                <label className="form-label">Password</label>
                                <div className="form-outline mb-4">
                                  <input
                                    type="password"
                                    id="form2Example22"
                                    className="form-control"
                                    onChange={(e) => {
                                      e.persist();
                                      setLogin((x) => ({
                                        ...x,
                                        password: e.target.value,
                                      }));
                                    }}
                                    value={login.password}
                                  />
                                  {errors.password && (
                                    <Label className="text-danger">
                                      {errors.password}
                                    </Label>
                                  )}
                                </div>

                                <div className="text-center pt-1 mb-5 pb-1">
                                  <button
                                    className="btn btn-dark btn-block fa-lg mb-3"
                                    type="button"
                                    onClick={handleLogin}
                                  >
                                    Log in
                                  </button>
                                  <a
                                    className="text-muted"
                                    href="/forgot-password"
                                  >
                                    Forgot password?
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
