import React from "react";

import { Container, Nav, NavItem, NavLink, Row } from "reactstrap";

interface NavTabsProps {
  route: "finance" | "users";
}

export const NavTabs = (props: NavTabsProps) => {
  return (
    <>
      <div className="theme theme-white">
        <Container>
          <Row>
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={props.route === "finance"}
                  href={`/admin/finance`}
                >
                  Finances
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={props.route === "users"} href={`/admin/users`}>
                  Users
                </NavLink>
              </NavItem>
            </Nav>
          </Row>
        </Container>
      </div>
    </>
  );
};
