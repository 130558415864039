import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faPencil,
  faTimes,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as variableModel from "../../../models/variable";
import { IVariable } from "../../../models/variable";

interface DocumentActionsProps {
  variable: IVariable;
}

export const VariableActions = (props: DocumentActionsProps) => {
  const [actionOpen, setActionOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [editIsOpen, setEditIsOpen] = useState<boolean>(false);
  const [variable, setVariable] = useState<IVariable>(props.variable);

  const deleteDocument = async () => {
    await variableModel.deleteOne(props.variable.id);
    window.location.reload();
  };

  return (
    <>
      <Modal
        isOpen={confirmDelete}
        toggle={() => setConfirmDelete(!confirmDelete)}
      >
        <ModalHeader>Delete variable</ModalHeader>
        <ModalBody>Are you sure you want to delete this variable?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteDocument()}>
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Yes, Delete
          </Button>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={editIsOpen} toggle={() => setEditIsOpen((x) => !x)}>
        <ModalHeader>Edit document</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="txtValue">Value for {variable.name}</Label>
            <Input
              id="txtValue"
              value={variable.value}
              onChange={(e) => {
                e.persist();
                setVariable((x) => ({
                  ...x,
                  value: e.target.value,
                }));
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="dark"
            onClick={async () => {
              await variableModel.update(variable);
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save
          </Button>
          <Button onClick={() => setEditIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Dropdown isOpen={actionOpen} toggle={() => setActionOpen((x) => !x)}>
        <DropdownToggle caret>
          <FontAwesomeIcon icon={faCog} className="mr-2" />
          Actions
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setEditIsOpen(true)}>
            <FontAwesomeIcon icon={faPencil} className="mr-2" />
            Edit
          </DropdownItem>
          <DropdownItem onClick={() => setConfirmDelete(true)}>
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
