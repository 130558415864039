import axios from "axios";
import { IUser } from "./user";
import * as yup from "yup";
import { IJob } from "./job";
import { IContact } from "./contacts";

export interface IContactJob {
  id?: string;
  contactId?: string;
  jobId?: string;
  rate?: string;
  applied?: boolean;
  reminded?: boolean;
  notInterested?: boolean;
  noticePeriod?: string;
  resumeCover?: any;
  criteriaCover?: any;
  createdBy?: string;
  serviceFee?: string;
  payrollFee?: string;
  summary?: string;
  contact?: IContact;
  job?: IJob;
}

export let create = async (contactJob: IContactJob) => {
  contactJob.createdBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/contactJob/create",
    { data: contactJob },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let list = async (contactId: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contactJob/list",
    {
      params: { contactId: contactId },
      withCredentials: true,
    }
  );
  return response;
};

export let listForJob = async (jobId: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contactJob/listForJob",
    {
      params: { jobId: jobId },
      withCredentials: true,
    }
  );
  return response;
};

export let markAsReminded = async (contactJobs: IContactJob[]) => {
  for (let i = 0; i < contactJobs.length; i++) {
    let contactJob = contactJobs[i];
    contactJob.resumeCover = null;
    contactJob.criteriaCover = null;
    contactJob.summary = null;
    if (!contactJob.reminded && !contactJob.applied) {
      contactJob.createdBy = window.$user.id;
      contactJob.reminded = true;
      await axios.post(
        process.env.REACT_APP_API_URL + "/contactJob/markAsReminded",
        { data: contactJob },
        {
          withCredentials: true,
        }
      );
    }
  }
};

export let updateApplied = async (contactJobs: IContactJob[]) => {
  for (let i = 0; i < contactJobs.length; i++) {
    let contactJob = contactJobs[i];
    contactJob.resumeCover = null;
    contactJob.criteriaCover = null;
    contactJob.summary = null;
    if (!contactJob.applied) {
      contactJob.applied = true;
      await axios.post(
        process.env.REACT_APP_API_URL + "/contactJob/updateApplied",
        { data: contactJob },
        {
          withCredentials: true,
        }
      );
    }
  }
};

export const contactJobSchema = yup.object().shape({
  rate: yup.string().required("Rate is required").defined("Rate is required"),
  jobId: yup
    .string()
    .required("Opportunity is required")
    .defined("Opportunity is required"),
});

export let get = async (id: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contactJob/get",
    {
      params: { id: id },
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (contactJob: IContactJob) => {
  contactJob.resumeCover = null;
  contactJob.criteriaCover = null;
  contactJob.job = null;
  contactJob.contact = null;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/contactJob/update",
    { data: contactJob },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let build = async (id: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contactJob/build",

    {
      responseType: "blob",
      params: { id: id },
      withCredentials: true,
    }
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `ResumeCover.pdf`); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const contactJobEditSchema = yup.object().shape({
  rate: yup.string().required("Rate is required").defined("Rate is required"),
  serviceFee: yup
    .string()
    .nullable()
    .required("Service fee is required")
    .defined("Service fee is required"),
  payrollFee: yup
    .string()
    .nullable()
    .required("Payroll fee is required")
    .defined("Payroll fee is required"),
  noticePeriod: yup
    .string()
    .nullable()
    .required("Notice Period is required")
    .defined("Notice Period is required"),
});

export let downloadResumeCover = async (id: string, name: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contactJob/downloadResumeCover",
    {
      responseType: "blob",
      params: { id: id },
      withCredentials: true,
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `ResumeCover-${name}.pdf`); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export let downloadCriteriaCover = async (id: string, name: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contactjob/downloadCriteriaCover",
    {
      responseType: "blob",
      params: { id: id },
      withCredentials: true,
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `CriteriaCover-${name}.pdf`); //or any other extension
  document.body.appendChild(link);
  link.click();
};
