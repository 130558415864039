export const getStatusBadgeColor = (status: string) => {
  let color: string;
  switch (status) {
    case "New":
    case "Contacted":
    case "Interested":
    case "Applied":
      color = "primary";
      break;
    case "Not Interested":
    case "Lost":
      color = "danger";
      break;
    case "Won":
      color = "success";
      break;
    default:
      color = "info";
      break;
  }
  return color;
};
