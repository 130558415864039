import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as accountModel from "../../../models/account";
import * as noteModel from "../../../models/note";
import { Alert, Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { NavTabs } from "../navTabs";
import LoadingOverlay from "react-loading-overlay-ts";
import * as yup from "yup";
import moment from "moment";

interface AccountNotesProps {
  match: any;
}

const notesSchema = yup
  .string()
  .required("Notes description is required")
  .defined("Notes description is required");

export const AccountNotes = (props: AccountNotesProps) => {
  const [account, setAccount] = useState<accountModel.IAccount>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [notes, setNotes] = useState<noteModel.INote[]>();
  const [note, setNote] = useState<noteModel.INote>({ description: "" });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    initialise();
  }, []);

  const createNote = async () => {
    setErrors({});
    try {
      await notesSchema.validateSync(note.description, {
        abortEarly: false,
      });
      noteModel.createAccountNote(note);
      window.location.reload();
    } catch (yupErrors) {
      let errors = {};
      yupErrors.inner.map((x: any) => {
        errors["description"] = x.message;
      });
      setErrors(errors);
    }
  };

  const initialise = async () => {
    let response = await accountModel.get(props.match.params.id);
    let account: accountModel.IAccount = response.data.account;

    let noteResponse = await noteModel.listForAccount(account.id);
    let notes: noteModel.INote[] = noteResponse.data.notes;

    setNote((x) => ({ ...x, accountId: account.id }));

    setNotes(notes);
    setAccount(account);
    setIsloading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Account notes | Acader Contract Management System</title>
      </Helmet>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>{account && <>{account.name}</>}</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => (window.location.href = "/accounts")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                  Accounts
                </button>
                <br />
                <br />
                {account && (
                  <>
                    <NavTabs
                      activeTab="notes"
                      id={account.id}
                      route="account"
                    />
                    <h2 className="mt-3">Notes</h2>
                    <hr />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="txtNote" className="required">
                            Add a note
                          </Label>
                          <Input
                            id="txtNote"
                            type="textarea"
                            value={note.description}
                            onChange={(e) => {
                              e.persist();
                              setNote((x) => ({
                                ...x,
                                description: e.target.value,
                              }));
                            }}
                          ></Input>
                          {errors.description && (
                            <Label className="text-danger">
                              {errors.description}
                            </Label>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button color="dark" onClick={createNote}>
                          <FontAwesomeIcon icon={faSave} className="mr-2" />
                          Add a note
                        </Button>
                      </Col>
                    </Row>
                    <hr />

                    {notes &&
                      notes.map((x, i) => {
                        return (
                          <Row key={i}>
                            <Col>
                              <Alert color="warning">
                                <blockquote>{x.description}</blockquote>
                                <small>
                                  Modified by: {x.ModifiedByUser.firstName}{" "}
                                  {x.ModifiedByUser.lastName}
                                </small>
                                <br />
                                <small>
                                  Modified on:{" "}
                                  {moment(x.updatedAt).format("Do MMM YYYY")}
                                </small>
                              </Alert>
                            </Col>
                          </Row>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
