import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as contactModel from "../../../models/contacts";
import { NavTabs } from "../navTabs";

interface ContactActivitiesProps {
  match: any;
}

export const ContactActivities = (props: ContactActivitiesProps) => {
  const [contact, setContact] = useState<contactModel.IContact>();
  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response = await contactModel.get(props.match.params.id);
    let contact: contactModel.IContact = response.data.contact;
    setContact(contact);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Contact activities | Acader Contract Management System</title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>
                {contact && (
                  <>
                    {contact.firstName} {contact.lastName}
                  </>
                )}
              </h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-dark"
                onClick={() => (window.location.href = "/contacts")}
              >
                <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                Contacts
              </button>
              <br />
              <br />
              {contact && (
                <>
                  <NavTabs activeTab="activities" id="" route="contact" />
                  <h2 className="mt-3">Activities</h2>
                  <hr />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
