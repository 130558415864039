import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { NavTabs } from "./navTabs";
import * as auth from "../../../models/auth";
import * as yup from "yup";

const passwordSchema = yup
  .string()
  .required("Password is required")
  .defined("Password is required");

export const ChangePassword = () => {
  const [password, setPassword] = useState<string>();
  const [errors, setErrors] = useState<any>({});

  const changePassword = async () => {
    setErrors({});
    try {
      await passwordSchema.validateSync(password, {
        abortEarly: false,
      });
      let success = await auth.changePassword(window.$user.email, password);
      if (success) {
        window.location.reload();
      }
    } catch (yupErrors) {
      let errors = {};
      yupErrors.inner.map((x: any) => {
        errors["password"] = x.message;
      });
      setErrors(errors);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Change password | Acader Contract Management System</title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Settings</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-white">
        <Container>
          <Row>
            <Col>
              <NavTabs route="change-password" />
              <h2 className="mt-4">Change password</h2>
              <hr />
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="txtPassword" className="required">
                      Please enter your new password
                    </Label>
                    <Input
                      id="txtPassword"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        e.persist();
                        setPassword(e.target.value);
                      }}
                    />
                    {errors.password && (
                      <Label className="text-danger">{errors.password}</Label>
                    )}
                  </FormGroup>
                  <Button color="dark" onClick={changePassword}>
                    Change password
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
