import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row, Table } from "reactstrap";
import * as variableModel from "../../../models/variable";
import { NavTabs } from "./navTabs";
import { VariableActions } from "./variableActions";

export const Variables = () => {
  const [variables, setVariables] = useState<variableModel.IVariable[]>();

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response = await variableModel.list();
    let variables: variableModel.IVariable[] = response.data.variables;
    setVariables(variables);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Variables | Acader Contract Management System</title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Settings</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-white">
        <Container>
          <Row>
            <Col>
              <NavTabs route="variables" />
              <h2 className="mt-4">Variables</h2>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive">
                <Table striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {variables &&
                      variables.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>{x.name}</td>
                            <td>{x.value}</td>
                            <td>
                              <VariableActions variable={x} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
