import axios from "axios";
import { IUser } from "./user";

export interface INote {
  id?: string;
  description?: string;
  contactId?: string;
  accountId?: string;
  modifiedBy?: string;
  createdBy?: string;
  updatedAt?: string;
  ModifiedByUser?: IUser;
}

export let list = async (contactId: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contact/notes",
    {
      params: { contactId: contactId },
      withCredentials: true,
    }
  );
  return response;
};

export let listForAccount = async (accountId: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/account/notes",
    {
      params: { accountId: accountId },
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (note: INote) => {
  note.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/note/update",
    { data: note },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let createContactNote = async (note: INote) => {
  note.modifiedBy = window.$user.id;
  note.createdBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/contact/note/create",
    { data: note },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let createAccountNote = async (note: INote) => {
  note.modifiedBy = window.$user.id;
  note.createdBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/account/note/create",
    { data: note },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let deleteOne = async (noteId: string) => {
  let response: any = await axios.delete(
    process.env.REACT_APP_API_URL + "/note/destroy",
    {
      params: { id: noteId },
      withCredentials: true,
    }
  );
  return response;
};
