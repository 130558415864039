import {
  faChartLine,
  faChartSimple,
  faCheckDouble,
  faClipboardCheck,
  faClipboardList,
  faCoins,
  faTasks,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";

export const Dashboard = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Dashboard | Acader Contract Management System</title>
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>Dashboard</h1>
              <div className="h1Divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-white">
        <Container className="text-center mt-5">
          <Row>
            <Col>
              <a href="/expiring-jobs" title="Expiring Jobs">
                <FontAwesomeIcon icon={faTasks} size="10x" color="#666" />
              </a>
              <p>Expiring Jobs</p>
            </Col>
            <Col>
              <a href="/advertising" title="Money spent on ads">
                <FontAwesomeIcon icon={faCoins} size="10x" color="#666" />
              </a>
              <p>Money spent on Ads</p>
            </Col>
            <Col>
              <a href="/resources-overview" title="Resources overview">
                <FontAwesomeIcon icon={faChartLine} size="10x" color="#666" />
              </a>
              <p>Resourcing overview</p>
            </Col>
            <Col>
              <a href="/jobs-overview" title="Jobs overview">
                <FontAwesomeIcon icon={faChartSimple} size="10x" color="#666" />
              </a>
              <p>Jobs overview</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <a href="/leads-overview" title="Leads overview">
                <FontAwesomeIcon icon={faUserCheck} size="10x" color="#666" />
              </a>
              <p>Leads overview</p>
            </Col>
            <Col>
              <a href="/accounts-overview" title="Accounts overview">
                <FontAwesomeIcon icon={faCheckDouble} size="10x" color="#666" />
              </a>
              <p>Accounts overview</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
