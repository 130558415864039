import axios from "axios";

export interface IDocument {
  id: string;
  fileName: string;
  type: string;
  mimeType: string;
  modifiedBy: string;
  createdBy: string;
}

export let list = async (contactId: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/contact/attachments",
    {
      params: { contactId: contactId },
      withCredentials: true,
    }
  );
  return response;
};

export let listForAccount = async (accountId: string) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/account/attachments",
    {
      params: { accountId: accountId },
      withCredentials: true,
    }
  );
  return response;
};

export let update = async (document: IDocument) => {
  document.modifiedBy = window.$user.id;
  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/attachment/update",
    { data: document },
    {
      withCredentials: true,
    }
  );
  return response;
};

export let createContactDocument = async (files: File[], contactId) => {
  let postContent = new FormData();
  postContent.append("contactId", contactId);
  postContent.append("modifiedBy", window.$user.id);
  postContent.append("createdBy", window.$user.id);
  postContent.append("type", "Supporting Documents");
  files.map((x) => {
    postContent.append("file", x);
  });

  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/contact/attachment/create",
    postContent,
    {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response;
};

export let createAccountDocument = async (files: File[], accountId) => {
  let postContent = new FormData();
  postContent.append("accountId", accountId);
  postContent.append("modifiedBy", window.$user.id);
  postContent.append("createdBy", window.$user.id);
  postContent.append("type", "Supporting Documents");
  files.map((x) => {
    postContent.append("file", x);
  });

  let response: any = await axios.post(
    process.env.REACT_APP_API_URL + "/account/attachment/create",
    postContent,
    {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response;
};

export let deleteOne = async (documentId: string) => {
  let response: any = await axios.delete(
    process.env.REACT_APP_API_URL + "/attachment/destroy",
    {
      params: { id: documentId },
      withCredentials: true,
    }
  );
  return response;
};

export let download = async (attachment: IDocument) => {
  let response: any = await axios.get(
    process.env.REACT_APP_API_URL + "/attachment/download",
    {
      responseType: "blob",
      params: { id: attachment.id },
      withCredentials: true,
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", attachment.fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
};
