import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil, faUser } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as userModel from "../../../models/user";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import { NavTabs } from "./navTabs";

export const UsersPage = () => {
  const [users, setUsers] = useState<userModel.IUser[]>();
  const [roles, setRoles] = useState<userModel.IRole[]>();
  const [newUser, setNewUser] = useState<userModel.IUser>(userModel.initUser);

  const [existingUser, setExistingUser] = useState<userModel.IUser>(
    userModel.initUser
  );

  const [errors, setErrors] = useState<userModel.IUser>({});
  const [newModalIsOpen, setNewModalIsOpen] = useState<boolean>(false);
  const [newExistingModalIsOpen, setExistingModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    initialise();
  }, []);

  useEffect(() => {
    if (existingUser && existingUser.id) {
      setExistingModalIsOpen(true);
    }
  }, [existingUser]);

  const initialise = async () => {
    let response = await userModel.list();
    if (response.status === 401) {
      window.location.href = "/unauthorised";
    }
    let users: userModel.IUser[] = response.data.users;

    let roleResponse = await userModel.getRoles();
    let roles: userModel.IRole[] = roleResponse.data.roles;

    setUsers(users);
    setRoles(roles);
    setIsloading(false);
  };

  const handleRolesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    newRole: userModel.IRole
  ) => {
    let roles = newUser.roles;
    if (e.target.checked) {
      roles.push(newRole);
    } else {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].name === newRole.name) {
          roles.splice(i, 1);
        }
      }
    }
    setNewUser((x) => ({ ...x, roles: roles }));
  };

  const handExistingRoleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    newRole: userModel.IRole
  ) => {
    let roles = existingUser.roles;
    if (e.target.checked) {
      roles.push(newRole);
    } else {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].name === newRole.name) {
          roles.splice(i, 1);
        }
      }
    }
    setExistingUser((x) => ({ ...x, roles: roles }));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Admin | Acader Contract Management System</title>
      </Helmet>

      <Modal
        size="lg"
        isOpen={newExistingModalIsOpen}
        toggle={() => setExistingModalIsOpen((x) => !x)}
      >
        <ModalHeader>Modify User</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="txtFirstname" className="required">
                  First name
                </Label>
                <Input
                  id="txtFirstName"
                  value={existingUser.firstName}
                  onChange={(e) => {
                    e.persist();
                    setExistingUser((x) => ({
                      ...x,
                      firstName: e.target.value,
                    }));
                  }}
                />
                {errors.firstName && (
                  <Label className="text-danger">{errors.firstName}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="txtLastName" className="required">
                  Last name
                </Label>
                <Input
                  id="txtLastName"
                  value={existingUser.lastName}
                  onChange={(e) => {
                    e.persist();
                    setExistingUser((x) => ({
                      ...x,
                      lastName: e.target.value,
                    }));
                  }}
                />
                {errors.lastName && (
                  <Label className="text-danger">{errors.lastName}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="txtPosition" className="required">
                  Position
                </Label>
                <Input
                  id="txtPosition"
                  value={existingUser.position}
                  onChange={(e) => {
                    e.persist();
                    setExistingUser((x) => ({
                      ...x,
                      position: e.target.value,
                    }));
                  }}
                />
                {errors.position && (
                  <Label className="text-danger">{errors.position}</Label>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="txtEmail" className="required">
                  Email
                </Label>
                <Input
                  id="txtEmail"
                  type="email"
                  value={existingUser.email}
                  onChange={(e) => {
                    e.persist();
                    setExistingUser((x) => ({
                      ...x,
                      email: e.target.value,
                    }));
                  }}
                />
                {errors.email && (
                  <Label className="text-danger">{errors.email}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="txtPhone" className="required">
                  Phone
                </Label>
                <Input
                  id="txtPhone"
                  value={existingUser.phone}
                  onChange={(e) => {
                    e.persist();
                    setExistingUser((x) => ({
                      ...x,
                      phone: e.target.value,
                    }));
                  }}
                />
                {errors.phone && (
                  <Label className="text-danger">{errors.phone}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Roles</Label>
                <br />
                {roles &&
                  roles.map((x, i) => {
                    return (
                      <FormGroup check inline key={i}>
                        <Input
                          type="checkbox"
                          defaultChecked={
                            existingUser.roles.find(
                              (y) => y.name === x.name
                            ) !== undefined
                          }
                          onChange={(e) => {
                            e.persist();
                            handExistingRoleChange(e, x);
                          }}
                        />
                        <Label check>{x.name}</Label>
                      </FormGroup>
                    );
                  })}
                {errors.roles && (
                  <Label className="text-danger">{errors.roles}</Label>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              setErrors({});
              try {
                await userModel.userSchema.validateSync(existingUser, {
                  abortEarly: false,
                });
                await userModel.update(existingUser);
                window.location.reload();
              } catch (yupErrors) {
                let errors: userModel.IUser = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Save
          </Button>
          <Button
            onClick={() => {
              setExistingModalIsOpen(false);
              setExistingUser(userModel.initUser);
              setErrors({});
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="lg"
        isOpen={newModalIsOpen}
        toggle={() => setNewModalIsOpen((x) => !x)}
      >
        <ModalHeader>New User</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="txtFirstname" className="required">
                  First name
                </Label>
                <Input
                  id="txtFirstName"
                  value={newUser.firstName}
                  onChange={(e) => {
                    e.persist();
                    setNewUser((x) => ({
                      ...x,
                      firstName: e.target.value,
                    }));
                  }}
                />
                {errors.firstName && (
                  <Label className="text-danger">{errors.firstName}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="txtLastName" className="required">
                  Last name
                </Label>
                <Input
                  id="txtLastName"
                  value={newUser.lastName}
                  onChange={(e) => {
                    e.persist();
                    setNewUser((x) => ({
                      ...x,
                      lastName: e.target.value,
                    }));
                  }}
                />
                {errors.lastName && (
                  <Label className="text-danger">{errors.lastName}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="txtPosition" className="required">
                  Position
                </Label>
                <Input
                  id="txtPosition"
                  value={newUser.position}
                  onChange={(e) => {
                    e.persist();
                    setNewUser((x) => ({
                      ...x,
                      position: e.target.value,
                    }));
                  }}
                />
                {errors.position && (
                  <Label className="text-danger">{errors.position}</Label>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="txtEmail" className="required">
                  Email
                </Label>
                <Input
                  id="txtEmail"
                  type="email"
                  value={newUser.email}
                  onChange={(e) => {
                    e.persist();
                    setNewUser((x) => ({
                      ...x,
                      email: e.target.value,
                    }));
                  }}
                />
                {errors.email && (
                  <Label className="text-danger">{errors.email}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="txtPhone" className="required">
                  Phone
                </Label>
                <Input
                  id="txtPhone"
                  value={newUser.phone}
                  onChange={(e) => {
                    e.persist();
                    setNewUser((x) => ({
                      ...x,
                      phone: e.target.value,
                    }));
                  }}
                />
                {errors.phone && (
                  <Label className="text-danger">{errors.phone}</Label>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Roles</Label>
                <br />
                {roles &&
                  roles.map((x, i) => {
                    return (
                      <FormGroup check inline key={i}>
                        <Input
                          type="checkbox"
                          onChange={(e) => {
                            e.persist();
                            handleRolesChange(e, x);
                          }}
                        />
                        <Label check>{x.name}</Label>
                      </FormGroup>
                    );
                  })}
                {errors.roles && (
                  <Label className="text-danger">{errors.roles}</Label>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              setErrors({});
              try {
                await userModel.userSchema.validateSync(newUser, {
                  abortEarly: false,
                });
                await userModel.create(newUser);
                window.location.reload();
              } catch (yupErrors) {
                let errors: userModel.IUser = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Save
          </Button>
          <Button
            onClick={() => {
              setNewModalIsOpen(false);
              setErrors({});
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <Container>
            <Row>
              <Col md={8}>
                <h1>Administrator's layer</h1>
                <div className="h1Divider"></div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="theme theme-white">
          <Container>
            <Row>
              <Col>
                <NavTabs route="users" />
                <h2 className="mt-3">Users</h2>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="btn btn-dark"
                  onClick={() => setNewModalIsOpen(true)}
                >
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  Add a new user
                </button>
                <br />
                <br />
                {users && (
                  <div className="table-responsive">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>First name</th>
                          <th>Last name</th>
                          <th>Email</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((x, i) => {
                          return (
                            <tr key={i}>
                              <td>{x.firstName}</td>
                              <td>{x.lastName}</td>
                              <td>{x.email}</td>
                              <td>
                                <button
                                  className="btn btn-dark"
                                  onClick={() => setExistingUser(x)}
                                >
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    className="mr-2"
                                  />
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    </>
  );
};
