import {
  faCheck,
  faPaperPlane,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import * as contactJobModel from "../../../models/contactJob";
import * as jobModel from "../../../models/job";

export interface JobsActionProps {
  job: jobModel.IJob;
}
export const JobsActions = (props: JobsActionProps) => {
  const [contactJobsIsOpen, setContactJobsIsOpen] = useState<boolean>(false);
  const [actionIsOpen, setActionIsOpen] = useState<boolean>(false);
  const [contactJobs, setContactJobs] =
    useState<contactJobModel.IContactJob[]>();
  const [selectedContactJobs, setSelectedContactJobs] = useState<
    contactJobModel.IContactJob[]
  >([]);

  const getContactJobs = async (jobId: string) => {
    let contactJobResponse = await contactJobModel.listForJob(jobId);
    let contactJobs = contactJobResponse.data.contactJobs;
    setContactJobs(contactJobs);
    setContactJobsIsOpen(true);
  };

  const handleSelectedContactJobs = (
    e: React.ChangeEvent<HTMLInputElement>,
    contactJob: contactJobModel.IContactJob
  ) => {
    let contactJobs = selectedContactJobs;
    if (e.target.checked) {
      contactJobs.push(contactJob);
    } else {
      for (let i = 0; i < contactJobs.length; i++) {
        if (contactJobs[i].id === contactJob.id) {
          contactJobs.splice(i, 1);
        }
      }
    }
    setSelectedContactJobs(contactJobs);
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={contactJobsIsOpen}
        toggle={() => setContactJobsIsOpen((x) => !x)}
      >
        <ModalHeader>Applicants</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Applied</th>
                  <th>Reminded</th>
                </tr>
              </thead>
              <tbody>
                {contactJobs &&
                  contactJobs.map((x, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              onChange={(e) => {
                                e.persist();
                                handleSelectedContactJobs(e, x);
                              }}
                            />
                          </FormGroup>
                        </td>
                        <td>
                          {x.contact &&
                            x.contact.firstName + " " + x.contact.lastName}
                        </td>
                        <td>
                          {x.applied ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} />
                          )}
                        </td>
                        <td>
                          {x.reminded ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              await contactJobModel.updateApplied(selectedContactJobs);
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Mark as applied
          </Button>
          <Button
            onClick={async () => {
              await contactJobModel.markAsReminded(selectedContactJobs);
              window.location.reload();
            }}
            className="ml-2"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
            Send reminder
          </Button>
          <Button onClick={() => setContactJobsIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <ButtonDropdown
        isOpen={actionIsOpen}
        toggle={() => setActionIsOpen((x) => !x)}
      >
        <DropdownToggle caret>Actions</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => getContactJobs(props.job.id)}>
            View applicants
          </DropdownItem>
          <DropdownItem
            onClick={() => (window.location.href = `/job/${props.job.id}`)}
          >
            Edit
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};
