import React from "react";

import { Nav, NavItem, NavLink, Row } from "reactstrap";

interface NavTabsProps {
  route: "change-password" | "variables" | "rate-calculator";
}

export const NavTabs = (props: NavTabsProps) => {
  return (
    <>
      <div className="theme theme-white">
        <div className="container">
          <div className="row">
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={props.route === "change-password"}
                  href={`/settings/change-password`}
                >
                  Change password
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={props.route === "variables"}
                  href={`/settings/variables`}
                >
                  Variables
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={props.route === "rate-calculator"}
                  href={`/settings/rate-calculator`}
                >
                  Rate calculator
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
    </>
  );
};
