import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row, Table } from "reactstrap";
import * as jobModel from "../../../models/job";
import { JobsActions } from "./jobsAction";
import LoadingOverlay from "react-loading-overlay-ts";

export const Jobs = () => {
  const [jobs, setJobs] = useState<jobModel.IJob[]>();
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response = await jobModel.list();
    let variables: jobModel.IJob[] = response.data.jobs;
    setJobs(variables);
    setIsloading(false);
  };

  const getDealineString = (deadline: Date) => {
    return moment(deadline).format("Do MMM YYYY");
  };

  const hasWarning = (deadline: Date) => {
    let today = moment(new Date());
    let deadlineMoment = moment(deadline);
    var diffDays = deadlineMoment.diff(today, "days");
    return diffDays <= 1;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Jobs | Acader Contract Management System</title>
      </Helmet>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>Jobs</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white">
          <Container>
            <Row>
              <Col>
                <button
                  className="btn btn-dark"
                  onClick={() => (window.location.href = "/job/0")}
                >
                  <FontAwesomeIcon icon={faFileContract} className="mr-2" />
                  Add a new job
                </button>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive jobs-table">
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Opportunity Id</th>
                        <th>Job title</th>
                        <th>Department</th>
                        <th>Deadline</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobs &&
                        jobs.map((x, i) => {
                          return (
                            <tr key={i}>
                              <td>{x.opportunityId}</td>
                              <td>{x.title}</td>
                              <td>{x.departmentName}</td>
                              <td
                                className={`${
                                  hasWarning(x.deadline) && "bg-warning"
                                }`}
                              >
                                {getDealineString(x.deadline)}
                              </td>
                              <td>
                                <JobsActions job={x} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    </>
  );
};
