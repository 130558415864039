/* eslint-disable react/jsx-no-target-blank */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotFoundPage } from "./pages/notfound";
import { SecureLayout } from "./pages/secure";
import { HomePage } from "./pages/public/home";
import { ForgotPassword } from "./pages/public/forgot-password";
import { ResetPassword } from "./pages/public/reset-password";
import { NotAuthorisedPage } from "./pages/unauthorised";

export interface LayoutProps {}

export const Layout = (props: LayoutProps) => {
  return (
    <>
      <div className="holder">
        <div className="wrapper">
          <div className="theme-white header">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <div className="row banner">
                    <div className="col-3">
                      <a href="/" className="logo">
                        <img
                          className="img-fluid"
                          src="/images/logo.png"
                          alt="acader.com.au"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-3 text-right"></div>
              </div>
            </div>
          </div>

          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route path="/unauthorised" component={NotAuthorisedPage} />
            <Route path="/:route" component={SecureLayout}></Route>
          </Switch>

          <div className="theme theme-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-8 text-center text-md-left">
                  2022 <i className="fas fa-copyright"></i> Copyright Acader Pty
                  Ltd. All rights Reserved
                </div>
                <div className="col-md-4 powered-by text-center text-md-right">
                  <label>Powered by </label>
                  <a href="https://acader.com.au" target="_blank">
                    <img
                      src="/images/acader_logo.png"
                      alt="Acader pty ltd"
                      className="img-fluid ml-2"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
